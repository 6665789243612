import React from 'react'
import IconChevronLeft from '@vizeat/components/es6/assets/icons/IconChevronLeft'
import IconChevronRight from '@vizeat/components/es6/assets/icons/IconChevronRight'
import { DirectionIcon, directionIconDefaultProps, directionIconPropTypes } from './DirectionIcon'

export function HorizontalDirectionChevronIcon({ direction, ...props }) {
  return <DirectionIcon direction={direction} IconLeft={IconChevronLeft} IconRight={IconChevronRight} {...props} />
}

HorizontalDirectionChevronIcon.propTypes = {
  ...directionIconPropTypes,
}

HorizontalDirectionChevronIcon.defaultProps = {
  ...directionIconDefaultProps,
}
