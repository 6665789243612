// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
// hooks
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useLocale } from 'hooks/locales/useLocale'
// components
import { SmartLink } from 'components/shared/SmartLink'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Container } from '@vizeat/components/es6/components/Container'
import { Text } from '@vizeat/components/es6/components/Text'
import { Button } from '@vizeat/components/es6/components/Button'
import { HorizontalDirectionChevronIcon } from 'components/shared/icons'
import { NavItem } from './styled'
// data
import data from 'static/prismic/MegaMenu.json'
import IconChevronDown from '@vizeat/components/es6/assets/icons/IconChevronDown'

const DesktopTrigger = styled(Box)`
  background: transparent;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  &:hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkGray};
  }
  transition: all 0.25s 0.25s;
`

const DesktopMenu = styled(Flex)`
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  cursor: default;
  transition: all 0.25s 0.25s;
  ${DesktopTrigger}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const StyledTitleLink = styled(SmartLink)`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mediumGray};
  ${({ theme }) => theme.media.desktop`
    color: ${theme.colors.darkGray};
  `}
`

const StyledLink = styled(SmartLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.mediumGray};
  ${(props) => props.theme.media.desktop`
    color: ${props.theme.colors.darkGray};
  `}
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledAdditionalLink = styled(SmartLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary} !important;
`

const MobileSubmenuTrigger = styled(Button)`
  width: auto;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`

const LinksWrapper = styled.ul`
  display: grid;
  gap: 24px;
  padding: 0;
  margin: 0;
  list-style: none;

  ${({ theme }) => theme.media.tablet`
    gap: 8px;
  `}
`

function Title({ value, href }) {
  const matches = useMediaQuery('desktop')
  if (!value && !href) return null
  return href ? (
    <StyledTitleLink href={href}>{value}</StyledTitleLink>
  ) : (
    <Text size='s' as='span' type='attention' color={matches ? 'darkGray' : 'mediumGray'}>
      {value}
    </Text>
  )
}

Title.propTypes = {
  value: PropTypes.string.isRequired,
  href: PropTypes.string,
}

Title.defaultProps = {
  href: undefined,
}

function Links({ links }) {
  // Make sure, stopping the event click propagation, that the side menu does not close by clicking the link
  return links.length > 0 ? (
    <LinksWrapper>
      {links.map((link) => (
        <li key={link.value}>
          <StyledLink href={link.href} onClick={(ev) => ev.stopPropagation()}>
            {link.value}
          </StyledLink>
        </li>
      ))}
    </LinksWrapper>
  ) : null
}

Links.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export function MegaMenu({ as, fallbackHref, onMobileMegaMenuOpen, activeIndex, prismicKey, title, transparent }) {
  const { locale } = useLocale()
  const matches = useMediaQuery('desktop')
  const localeMenuColumns = data[locale]
  const menuColumns = localeMenuColumns && localeMenuColumns[prismicKey]
  const showMobileSubMenu = activeIndex === prismicKey

  function handleSubMenuOpening(ev) {
    ev.stopPropagation()
    onMobileMegaMenuOpen?.(prismicKey)
  }

  function getMenuContent(col) {
    return (
      <Flex
        flexDirection='column'
        flex={{ desktop: '1', default: 'none' }}
        whiteSpace={{ desktop: 'nowrap', default: 'none' }}
        mb={{ desktop: '0', default: '48px' }}
        gap={{ desktop: '16px', default: '48px' }}
        key={col.title.value}
        fontWeight='bolder'
      >
        {Object.keys(col).map((key) => {
          switch (key) {
            case 'title':
              return <Title key={key} {...col.title} />
            case 'links':
              return <Links key={key} links={col.links} />
            case 'additionalLink':
              return col.additionalLink.href ? (
                <StyledAdditionalLink key={key} href={col.additionalLink.href}>
                  {col.additionalLink.value}
                </StyledAdditionalLink>
              ) : null
            default:
              return null
          }
        })}
      </Flex>
    )
  }

  if (!menuColumns) {
    return (
      <Box as={as}>
        <NavItem href={fallbackHref} transparent={transparent}>
          {title}
        </NavItem>
      </Box>
    )
  }

  if (matches) {
    return (
      <Box position='static'>
        <DesktopTrigger p='12px'>
          <Text as='span' size='s' color='inherit' fontWeight='bolder'>
            {title}
            <IconChevronDown size='10px' ml='4px' />
          </Text>

          <DesktopMenu
            position='absolute'
            left='0'
            right='0'
            bg='white'
            p='24px'
            mt='12px'
            boxShadow='md'
            borderRadius='lg'
            gap='32px'
          >
            <Container display='flex'>{menuColumns.map(getMenuContent)}</Container>
          </DesktopMenu>
        </DesktopTrigger>
      </Box>
    )
  }

  return (
    <Flex as={as} flexDirection='column' alignItems='flex-start' fontSize='14px'>
      {showMobileSubMenu ? (
        menuColumns.map(getMenuContent)
      ) : (
        <MobileSubmenuTrigger clear onClick={handleSubMenuOpening}>
          {title}
          <HorizontalDirectionChevronIcon direction='forward' ml='16px' size='16px' />
        </MobileSubmenuTrigger>
      )}
    </Flex>
  )
}

MegaMenu.propTypes = {
  as: PropTypes.string,
  fallbackHref: PropTypes.string.isRequired,
  onMobileMegaMenuOpen: PropTypes.func,
  activeIndex: PropTypes.string,
  prismicKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  transparent: PropTypes.bool,
}

MegaMenu.defaultProps = {
  as: undefined,
  activeIndex: undefined,
  onMobileMegaMenuOpen: undefined,
  transparent: false,
}
