import settings from 'settings'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import loadScript from 'load-script'
import { Button } from '@vizeat/components/es6/components/Button'
import { Image } from '@vizeat/components/es6/components/Image'
// redux
import { loginWithFacebook } from 'redux/actions'

const version = 'v12.0'
const scopes = ['public_profile', 'email']

FacebookLoginButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export function FacebookLoginButton({ children }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!window.FB) {
      // Facebook format: ll_CC, where ll is a two-letter language code, and CC is a two-letter country code
      const { language, userLanguage, browserLanguage } = window.navigator
      const lng = (language || userLanguage || browserLanguage).replace('-', '_')
      loadScript(`https://connect.facebook.net/${lng}/sdk.js#xfbml=1&version=${version}`, (err, script) => {
        if (err || !window.FB) return
        window.FB.init({ appId: settings.facebookAppId, cookie: true, xfbml: true, version })
      })
    }
  }, [])

  function handleLogin(e) {
    e.preventDefault()
    if (!window.FB) return
    return window.FB.getLoginStatus(({ authResponse, status }) => {
      if (status === 'connected') return dispatch(loginWithFacebook({ authResponse }))
      return window.FB.login(
        ({ authResponse, status }) => {
          if (status === 'connected') dispatch(loginWithFacebook({ authResponse }))
        },
        { scope: scopes.join(',') },
      )
    })
  }

  return (
    <Button
      // Unfortunately must overload values from component-library...
      css={`
        background: ${({ theme }) => theme.colors.facebookBlue};
        &:hover:not([disabled]) {
          background: ${({ theme }) => theme.colors.facebookBlue};
          filter: brightness(120%);
        }
      `}
      onClick={handleLogin}
    >
      <Image
        width='10px'
        heigth='10px'
        src='/assets/images/icon-button-fb.svg'
        alt='Facebook login'
        css={{ marginRight: '8px' }}
      />
      {children}
    </Button>
  )
}
