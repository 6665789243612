// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useLocale } from 'hooks/locales/useLocale'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { CalendarPicker as Picker } from '@vizeat/components/es6/components/CalendarPicker'

export function CalendarPicker({ onDayClick, ...props }) {
  const { locale } = useLocale()
  const { isRTL } = useIsRtl()

  return <Picker locale={locale} onDayClick={onDayClick} {...props} rtl={isRTL} />
}

CalendarPicker.propTypes = {
  onDayClick: PropTypes.func.isRequired,
}
