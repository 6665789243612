import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const overrideSchema = yup.object({
  date: yup.string().required(),
  event_id: yup.number().required(),
  max_seats: yup.number().nullable().default(null),
  min_seats: yup.number().nullable().default(null),
  begins_at: yup.string().nullable().default(null),
  ends_at: yup.string().nullable().default(null),
  event_price: yup.number().nullable().default(null),
  price: yup.number().nullable().default(null),
  booking_deadline: yup.string().nullable().default(null),
  cancellation_deadline: yup.string().nullable().default(null),
  pe_booking_deadline: yup.string().nullable().default(null),
  pe_cancellation_deadline: yup.string().nullable().default(null),
  instant_booking: yup.boolean().nullable().default(null),
  privatized_by: yup.number().nullable().default(null),
  privatized_at: yup.string().nullable().default(null),
})

export function create(raw) {
  const data = cast(overrideSchema, raw)

  // Backend sends always booking_deadline and cancellation_deadline, so we duplicate the value
  //     - as a host, correctly compare with defaults values as in the calendar
  //     - as a guest, simply getting the value without issues during booking, summary, etc.
  const isPrivate = data.privatized_at || data.privatized_by

  return {
    ...data,
    pe_booking_deadline: isPrivate ? data.booking_deadline : null,
    pe_cancellation_deadline: isPrivate ? data.cancellation_deadline : null,
  }
}

export const emptyOverride = create()
