import React from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { updateCurrentUser } from 'redux/actions'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useLanguage } from 'hooks/languages/useLanguage'
import { useLocale } from 'hooks/locales/useLocale'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Text } from '@vizeat/components/es6/components/Text'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox'
import IconChevronDown from '@vizeat/components/es6/assets/icons/IconChevronDown'
import PropTypes from 'helpers/proptypes'

const MenuItem = styled.li`
  min-width: 150px;
  color: ${(props) => props.theme.colors.darkGray};
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lighterGray};
  font-size: 14px;
  display: block;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.lighterGray};
  }
`

function Menu({ title, children, listStyles }) {
  return (
    <div>
      <Heading type='medium' color='mediumGray'>
        {title}
      </Heading>
      <Box as='ul' p='0' m='0' css={listStyles}>
        {children}
      </Box>
    </div>
  )
}

Menu.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  listStyles: PropTypes.string,
}

Menu.defaultProps = {
  listStyles: undefined,
}

export function LocaleAndCurrency({ transparent }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { getExtendedLocale } = useLocale()
  const { appLanguage, languages, setAppLanguage } = useLanguage()
  const { appCurrency, currencies, setAppCurrency } = useCurrency()
  const currentUser = useSelector(getCurrentUser)

  async function handleLocaleChange(locale) {
    setAppLanguage(locale)
    if (currentUser.id) await dispatch(updateCurrentUser())
  }

  async function handleCurrencyChange(currencyIso3) {
    setAppCurrency(currencyIso3)
    if (currentUser.id) await dispatch(updateCurrentUser({ currency_iso3: currencyIso3 }))
  }

  const Trigger = (
    <Flex flexDirection='row' gap='4px' alignItems='center' px='12px' css='text-transform: uppercase; cursor: pointer;'>
      <Text as='span' size='s' color={transparent ? 'white' : 'mediumGray'} fontWeight='bolder'>
        {appLanguage.locale} / {appCurrency.iso_3}
      </Text>
      <IconChevronDown size='10px' color={transparent ? 'white' : 'mediumGray'} />
    </Flex>
  )

  return (
    <li>
      <Dropdown id='locale-n-currency' position='left' Trigger={Trigger}>
        {({ handleDropdownHide }) => (
          <Grid p='24px' gap='24px' gridTemplateColumns='minmax(200px, auto) minmax(auto, 300px)'>
            <Menu title={t('Header::Language')}>
              {languages.map(({ id, locale }) => (
                <MenuItem
                  key={id}
                  onClick={async () => {
                    await handleLocaleChange(locale)
                    handleDropdownHide()
                  }}
                >
                  <Checkbox
                    readOnly
                    mt='none'
                    checked={locale === appLanguage.locale}
                    label={getExtendedLocale(locale)}
                  />
                </MenuItem>
              ))}
            </Menu>

            <Menu title={t('Header::Currency')} listStyles='columns: 2;'>
              {currencies.map(({ iso_3: currencyIso3, symbol }) => (
                <MenuItem
                  key={currencyIso3}
                  onClick={async () => {
                    await handleCurrencyChange(currencyIso3)
                    handleDropdownHide()
                  }}
                >
                  <Checkbox
                    readOnly
                    mt='none'
                    checked={currencyIso3 === appCurrency.iso_3}
                    label={`${currencyIso3} ${symbol}`}
                  />
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        )}
      </Dropdown>
    </li>
  )
}

LocaleAndCurrency.propTypes = {
  transparent: PropTypes.bool.isRequired,
}
