import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { darken } from 'polished'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { HorizontalDirectionArrowIcon } from 'components/shared/icons/'
import { SmartLink } from 'components/shared/SmartLink'

const ROUNDED_ICON_SIZE = '56px'

const StyledButton = styled(({ href, ...props }) => (
  <Flex as={href ? SmartLink : 'button'} href={href} type={href ? undefined : 'button'} {...props} />
))`
  position: relative;
  align-items: center;
  justify-content: center;
  height: ${ROUNDED_ICON_SIZE};
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: calc(${ROUNDED_ICON_SIZE} + 12px);
  border: none;
  border-radius: ${({ theme }) => theme.radii.full};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme, $color }) => theme.colors[$color] || theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  font-size: 18px;
  text-wrap: balance;
  transition: all 0.2s;

  &:hover {
    color: ${({ theme, $color }) => darken(0.1, theme.colors[$color] || theme.colors.primary)};
  }

  ${(props) => props.theme.media.tablet`
    text-wrap: nowrap;
    padding-left: calc(${ROUNDED_ICON_SIZE} + 20px);
  `}
`

const ChevronWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: ${ROUNDED_ICON_SIZE};
  height: ${ROUNDED_ICON_SIZE};
  position: absolute;
  left: 0;
  top: 0;
  border: ${({ theme }) => `${theme.borders.sm} ${theme.colors.white}`};
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.full};
  background-color: ${({ theme }) => theme.colors.primary};
`

export function ChevronIconButton({ children, color, href, onClick }) {
  return (
    <StyledButton $color={color} href={href} onClick={onClick}>
      <ChevronWrapper>
        <HorizontalDirectionArrowIcon size='18px' color='white' />
      </ChevronWrapper>
      {children}
    </StyledButton>
  )
}

ChevronIconButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

ChevronIconButton.defaultProps = {
  color: 'primary',
  href: undefined,
  onClick: undefined,
}
