import * as yup from 'yup'
import { eventSchema } from './event'
import { userSchema } from './user'
import { placeSchema } from './place'
import { bookingSchema } from './booking'
import { cast } from 'helpers/schema'

export const requestSchema = yup.object({
  id: yup.number().optional(),
  place: placeSchema,
  additional_info: yup
    .object({
      locality: yup.string().nullable().default(null),
      country_iso: yup.string().nullable().default(null),
      datefrom: yup.date().nullable().default(null),
      dateto: yup.date().nullable().default(null),
      occasion: yup.string().nullable().default(null),
    })
    .nullable()
    .default({}),
  begins_at: yup.string(),
  bookings: yup.array().of(bookingSchema).default([]),
  conversation_id: yup.number().required(),
  date: yup.string().nullable().default(null),
  event_id: yup.number().required(),
  event: eventSchema,
  occasion: yup.string().nullable().default(null),
  origination: yup.string().nullable().default(null),
  user_id: yup.number().required(),
  user: userSchema,
  seats: yup.number().nullable().default(null),
  approved_at: yup.string().nullable().default(null),
  canceled_at: yup.string().nullable().default(null),
  expired_at: yup.string().nullable().default(null),
  privatized_at: yup.string().nullable().default(null),
  canceled_by: yup.number().nullable().default(null),
  invite_token: yup.string().required(),
  created_at: yup.string().nullable().default(null),
  updated_at: yup.string().nullable().default(null),
})

export function create(data) {
  return cast(requestSchema, data)
}

export const emptyRequest = create()
