import * as yup from 'yup'
import { cast } from 'helpers/schema'

export const placeLightSchema = yup.object({
  country: yup.string().nullable().default(null),
  locality: yup.string().nullable().default(null),
  country_iso: yup.string().nullable().default(null),
})

export function create(data) {
  return cast(placeLightSchema, data)
}

export const emptyPlaceLight = create()
