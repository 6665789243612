// libs
import { useEffect } from 'react'
import { useSafeState } from '@vizeat/components/es6/hooks'

export function useGeolocationStatus() {
  const [status, setStatus] = useSafeState(undefined)

  useEffect(() => {
    function handlePermissionChange() {
      setStatus(this.state)
    }

    async function queryGeolocationPermissions() {
      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
      permissionStatus.addEventListener('change', handlePermissionChange)
      setStatus(permissionStatus.state)

      return () => {
        permissionStatus.removeEventListener('change', handlePermissionChange)
      }
    }

    if (!navigator.geolocation) setStatus('denied')
    if (navigator.permissions) {
      queryGeolocationPermissions()
    }
  }, [setStatus])

  return status
}
