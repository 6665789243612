// libs
import React, { useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { useCombobox } from 'downshift'
import { useRouter } from 'next/router'
import { useTrackingQueryStrings } from 'hooks/tracking'
// components
import { DropdownContent } from './DropdownContent'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Box } from '@vizeat/components/es6/components/Box'
// helpers
import { COOKIE_KEYS, loadCookie } from 'helpers/cookies'
import { MobileModal } from '@vizeat/components/es6/components/MobileModal/MobileModal'
import { MobileModalTitle } from '@vizeat/components/es6/components/MobileModal'
import { useSearchAutocompleteQuery } from 'hooks/search/useSearchAutocompleteQuery'
import useDebouncedState from 'hooks/useDebouncedState'

const searchMobileInputStyles = css`
  appearance: none;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: 8px;
  height: 45px;
  font-size: 16px;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }
`

const StyledTriggerInput = styled.input`
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  ${({ forwardedStyle }) => forwardedStyle}
`

const MobileModalContent = styled.div`
  min-width: 100%;
  position: relative;
  width: 100%;
`

const MenuDropdown = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  display: inline-block;
  margin: 2px 0px 0px;
  max-width: 100%;
  min-width: 100%;
  text-align: left;

  ${({ theme, menuDropdownMaxWidth }) => theme.media.tablet`
    max-width: ${menuDropdownMaxWidth || '500px'};
  `}

  ${(props) => props.theme.media.phablet`
    box-shadow: ${(props) => props.theme.shadows.md};
    border-radius: 4px;
    padding: 8px 16px;
    top: 100%;
    left: 0;
    z-index: 1;
    position: absolute;
  `}
`

const StyledModalInput = styled.input`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.title};
  box-shadow: none;
  font-size: 16px !important;
  font-family: ${(props) => props.theme.fonts.text};
  width: 100%;
  box-sizing: border-box;
`

const StyledWrapper = styled(Box)`
  display: inline-block;
  position: relative;
  z-index: 12;
`

export function OpenSearchInput({
  onRecentSearchClick,
  menuDropdownMaxWidth,
  name,
  onPlaceSelect,
  onChange,
  placeholder,
  searchEventsParams,
  searchUsersParams,
  shouldSearchEvents,
  shouldSearchPlaces,
  shouldSearchUsers,
  showRecentSearches,
  showNearbyExperiences,
  triggerInputCss,
  value,
  autoFocus,
  renderTrigger,
  isDropdown,
  stateReducer,
  ...props
}) {
  const { t } = useTranslation()
  const router = useRouter()
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false)
  const isPhabletOrHigher = useMediaQuery('phablet')
  const [inputValue, setInputValue] = useState(value)
  const debouncedValue = useDebouncedState(inputValue)
  const keepTrackingQueryStrings = useTrackingQueryStrings()

  function handleToggleIsMobileModalOpen() {
    setIsMobileModalOpen((state) => !state)
  }

  const recentSearches = (loadCookie(COOKIE_KEYS.RECENT_SEARCHES) || [])
    .filter((search) => (search.dateto ? !moment(search.dateto).isBefore(moment.utc(), 'day') : true))
    .map((item) => ({
      item: {
        ...item,
        type: 'recentSearch',
      },
    }))

  const { autocompleteData, isFetched } = useSearchAutocompleteQuery({
    q: debouncedValue,
    shouldSearchEvents,
    shouldSearchUsers,
    shouldSearchPlaces,
  })

  const getItems = () => {
    if (autocompleteData.allResults.length > 0) return autocompleteData.allResults
    if (showRecentSearches) return recentSearches
    return []
  }

  const itemToString = (result) => {
    if (!result) return ''
    const { item } = result

    switch (item.type) {
      case 'places':
        return item.title
      default:
        return ''
    }
  }

  const onInputValueChange = (o) => {
    onChange(o.inputValue)
    setInputValue(o.inputValue)
  }

  const onSelectedItemChange = ({ selectedItem: { item } }) => {
    // Input field shown inside modal is device smaller than phablet breakpoint
    if (!isPhabletOrHigher) handleToggleIsMobileModalOpen()
    switch (item.type) {
      case 'events':
        return router.push(keepTrackingQueryStrings(`/events/${item.id}`))
      case 'places':
        if (onPlaceSelect) return onPlaceSelect(item)
        return router.push(keepTrackingQueryStrings({ pathname: '/search', query: { q: item.title, pid: item.id } }))
      case 'recentSearch':
        return router.push(keepTrackingQueryStrings({ pathname: '/search', query: item }))
      case 'users':
        return router.push(keepTrackingQueryStrings(`/users/${item.id}`))
    }
  }

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, openMenu } =
    useCombobox({
      initialInputValue: value,
      items: getItems(),
      itemToString,
      onInputValueChange,
      onSelectedItemChange,
      ...(stateReducer && { stateReducer }),
    })

  const mobileInputProps = getInputProps({ name, onFocus: openMenu, autoFocus: true, placeholder })
  const mobileComboboxProps = getComboboxProps()

  const handleFocus = (e) => {
    const inputValue = e.target.value
    setInputValue(inputValue)
    openMenu()
  }

  if (isPhabletOrHigher) {
    const inputProps = getInputProps({
      name,
      onFocus: handleFocus,
      placeholder,
      forwardedStyle: triggerInputCss,
      autoFocus,
    })

    const comboboxProps = getComboboxProps({ width: '100%', height: '100%', minWidth: '100%', minHeight: '100%' })

    const menuProps = getMenuProps()

    const List = (
      <DropdownContent
        allResults={autocompleteData.allResults}
        eventResults={autocompleteData.events}
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
        inputValue={value}
        isFetching={!isFetched}
        placeResults={autocompleteData.places}
        recentSearches={recentSearches}
        showNearbyExperiences={showNearbyExperiences}
        showRecentSearches={showRecentSearches}
        onRecentSearchClick={onRecentSearchClick}
        userResults={autocompleteData.users}
      />
    )

    return (
      <StyledWrapper {...props}>
        {renderTrigger?.(inputProps, comboboxProps) || (
          <Box {...comboboxProps}>
            <StyledTriggerInput {...inputProps} />
          </Box>
        )}

        {!isDropdown ? (
          <div {...menuProps}>{List}</div>
        ) : (
          isOpen && (
            <MenuDropdown {...menuProps} menuDropdownMaxWidth={menuDropdownMaxWidth}>
              {List}
            </MenuDropdown>
          )
        )}
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper {...props}>
      {renderTrigger?.(mobileInputProps, mobileComboboxProps, handleToggleIsMobileModalOpen) || (
        <Box width='100%' height='100%' minWidth='100%' minHeight='100%'>
          <StyledTriggerInput
            onClick={handleToggleIsMobileModalOpen}
            placeholder={placeholder}
            readOnly
            value={value}
            css={triggerInputCss}
          />
        </Box>
      )}

      <MobileModal
        show={isMobileModalOpen}
        onHide={handleToggleIsMobileModalOpen}
        Header={<MobileModalTitle content={t('OpenSearchInput::MobileModal::Search by keywords')} />}
        shouldFocusContent={false}
        variant='default'
      >
        <MobileModalContent>
          <div {...mobileComboboxProps}>
            <StyledModalInput {...mobileInputProps} css={searchMobileInputStyles} />
          </div>
          <MenuDropdown {...getMenuProps()}>
            <DropdownContent
              allResults={autocompleteData.allResults}
              eventResults={autocompleteData.events}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              inputValue={value}
              isFetching={!isFetched}
              placeResults={autocompleteData.places}
              recentSearches={recentSearches}
              showNearbyExperiences={showNearbyExperiences}
              showRecentSearches={showRecentSearches}
              onRecentSearchClick={onRecentSearchClick}
              userResults={autocompleteData.users}
            />
          </MenuDropdown>
        </MobileModalContent>
      </MobileModal>
    </StyledWrapper>
  )
}

OpenSearchInput.propTypes = {
  onRecentSearchClick: PropTypes.func,
  menuDropdownMaxWidth: PropTypes.string,
  name: PropTypes.string,
  onPlaceSelect: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchEventsParams: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  searchUsersParams: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  shouldSearchEvents: PropTypes.bool,
  shouldSearchPlaces: PropTypes.bool,
  shouldSearchUsers: PropTypes.bool,
  showNearbyExperiences: PropTypes.bool,
  showRecentSearches: PropTypes.bool,
  triggerInputCss: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  renderTrigger: PropTypes.func,
  isDropdown: PropTypes.bool,
  stateReducer: PropTypes.func,
}

OpenSearchInput.defaultProps = {
  onRecentSearchClick: undefined,
  menuDropdownMaxWidth: undefined,
  name: undefined,
  onPlaceSelect: undefined,
  onChange: undefined,
  placeholder: undefined,
  searchEventsParams: undefined,
  searchUsersParams: undefined,
  shouldSearchEvents: true,
  shouldSearchUsers: true,
  shouldSearchPlaces: true,
  showRecentSearches: false,
  showNearbyExperiences: false,
  triggerInputCss: undefined,
  value: undefined,
  autoFocus: false,
  renderTrigger: undefined,
  isDropdown: true,
  stateReducer: undefined,
}
