import * as yup from 'yup'
import { currencySchema } from './currency'
import { cast } from 'helpers/schema'

const campaignSchema = yup.object({
  active: yup.boolean().default(false),
  begin_at: yup.string().nullable().default(null),
  code: yup.string().required(),
  description: yup.string().nullable().default(null),
  end_at: yup.string().nullable().default(null),
  id: yup.number().optional(),
  title: yup.string().nullable().default(null),
})

const payeeSchema = yup.object({
  currency: currencySchema,
  price: yup.number().required(),
})

const payerSchema = yup.object({
  currency: currencySchema,
  payee_to_payer_conversion_rate: yup.number().default(1),
  unit_price: yup.number().required(),
  seats: yup.number().required(),
  sub_total: yup.number().required(),
  sub_total_with_fees: yup.number().required(),
  fees: yup.number().required(),
  discount: yup.number().default(0),
  total_paid: yup.number().required(),
  unit_price_with_fees: yup.number().required(),
})

const discountSchema = yup.object({
  currency: currencySchema,
  amount_in_guest_currency: yup.number().required(),
  amount_in_event_currency: yup.number().required(),
  amount_in_discount_currency: yup.number().required(),
})

const feesSchema = yup.object({
  rate: yup.number().default(1),
  currency: currencySchema,
  vizeat: yup.number().required(),
  conversion: yup.number().default(0),
  total: yup.number().required(),
})

const euroPricingSchema = yup.object({
  event_price: yup.number().required(),
  booking_price: yup.number().required(),
  sub_total: yup.number().required(),
  fees: yup.number().required(),
  discount: yup.number().nullable().default(0),
  total: yup.number().required(),
})

export const pricingSchema = yup.object({
  campaign: campaignSchema.nullable().default(null),
  metadata: yup.object({
    euro_pricing: euroPricingSchema,
  }),
  pricing: yup.object({
    discount: discountSchema,
    event_id: yup.number().required(),
    fees: feesSchema,
    payee: payeeSchema,
    payer: payerSchema,
  }),
})

export function create(data) {
  return cast(pricingSchema, data)
}

export function transform(raw = {}) {
  const { pricing, ...data } = create(raw)

  return {
    ...data,
    payee: pricing.payee,
    discount: pricing.discount,
    fees: pricing.fees,
    payer: pricing.payer,
  }
}

export const emptyPricing = create()
