import React, { PureComponent, Children } from 'react'
import PropTypes from 'helpers/proptypes' // components
import { LegacyDropdown } from '@vizeat/components/es6/components/LegacyDropdown'
import { Input } from '@vizeat/components/es6/components/Input'
import { Flex } from '@vizeat/components/es6/components/Flex'
import IconChevronDown from '@vizeat/components/es6/assets/icons/IconChevronDown'

export class LegacyDropdownInput extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    closeable: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownChildren: PropTypes.node,
    dropdownCloseEvent: PropTypes.string, // eg. onClick, onDayClick
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  }

  static defaultProps = {
    closeable: false,
    disabled: false,
    label: undefined,
    defaultOpen: false,
    description: undefined,
    dropdownChildren: undefined,
    dropdownCloseEvent: undefined,
    error: undefined,
    isRequired: false,
    placeholder: undefined,
    value: undefined,
  }

  ref = (Dropdown) => {
    this.Dropdown = Dropdown
    if (this.props.defaultOpen && this.Dropdown) this.Dropdown.open()
  }

  closeDropdown = () => {
    if (this.Dropdown) this.Dropdown.close()
  }

  Trigger = () => {
    const { value, placeholder, label, description, error, isRequired } = this.props

    return (
      <div>
        <Input
          description={description}
          error={error}
          isRequired={isRequired}
          key={value} // Force rerender, specially when value becomes undefined
          label={label}
          placeholder={!value ? placeholder : undefined}
          readOnly
          value={value}
          css={`
            &::placeholder {
              color: ${({ theme }) => theme.colors.black};
            }
          `}
        >
          <Flex
            position='absolute'
            right='0'
            top='0'
            p='8px'
            width='36px'
            height='100%'
            justifyContent='center'
            alignItems='center'
            color='mediumGray'
          >
            <IconChevronDown color='black' size='18px' />
          </Flex>
        </Input>
      </div>
    )
  }

  wrappedClickEvent =
    (child) =>
    (...args) => {
      child.props[this.props.dropdownCloseEvent](...args)
      this.closeDropdown()
    }

  render() {
    const { children, dropdownChildren, closeable, dropdownCloseEvent, disabled } = this.props

    // TODO refactor to react context (and refactor to functional component)
    const childrenWithProps = Children.map(children, (child) =>
      React.cloneElement(child, {
        [dropdownCloseEvent]: this.wrappedClickEvent(child),
        closeDropdown: this.closeDropdown,
      }),
    )

    return (
      <LegacyDropdown ref={this.ref} trigger={<this.Trigger />} closeable={closeable} disabled={disabled}>
        {dropdownChildren}
        {childrenWithProps}
      </LegacyDropdown>
    )
  }
}
