export function sanitize(str) {
  return new RegExp(str.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&'), 'gi')
}

export function toCamelCase(str) {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export function toSnakeCase(str) {
  return str.replace(/[A-Z]/g, (s) => `_${s.toLowerCase()}`)
}

export const urlRegex = /((?:http|https):\/\/[\w_-]+\.[eatwith.com||eat.lc]+(?:[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/g
