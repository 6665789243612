import { useMemo, useEffect, useReducer } from 'react'
import { useWishlistsQuery } from 'hooks/queries/wishlist'
import { useSelector, useDispatch } from 'react-redux'
import { getIsCurrentUserLoggedIn } from 'redux/selectors'
import { toggleLoginModal } from 'redux/actions'

export function useAddEventToWishlist(eventId) {
  const dispatch = useDispatch()

  const isUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)
  const { data: wishlists } = useWishlistsQuery()

  const isEventInWishlist = useMemo(() => {
    if (!wishlists) return false
    return wishlists.some(({ events }) => events.some(({ id }) => id === eventId))
  }, [eventId, wishlists])

  const initialState = {
    isModalOpen: false,
    shouldOpenModalAfterAuth: false,
  }
  const [{ isModalOpen, shouldOpenModalAfterAuth }, reducerDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'USER_MUST_AUTHENTICATE':
        return { ...state, shouldOpenModalAfterAuth: true }
      case 'USER_HAS_AUTHENTICATED':
        return { ...state, shouldOpenModalAfterAuth: false, isModalOpen: true }
      case 'OPEN_MODAL':
        return { ...state, isModalOpen: true }
      case 'CLOSE_MODAL':
        return { ...state, isModalOpen: false, shouldOpenModalAfterAuth: false }
      default:
        return state
    }
  }, initialState)

  useEffect(() => {
    if (isUserLoggedIn && shouldOpenModalAfterAuth) {
      reducerDispatch({ type: 'USER_HAS_AUTHENTICATED' })
    }
  }, [isUserLoggedIn, shouldOpenModalAfterAuth, reducerDispatch])

  function handleOpenModal() {
    if (isUserLoggedIn) return reducerDispatch({ type: 'OPEN_MODAL' })
    reducerDispatch({ type: 'USER_MUST_AUTHENTICATE' })
    dispatch(toggleLoginModal({ show: true }))
  }

  function handleCloseModal() {
    reducerDispatch({ type: 'CLOSE_MODAL' })
  }

  return {
    handleOpenModal,
    handleCloseModal,
    isEventInWishlist,
    isModalOpen,
  }
}
