// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { scaleCropFile } from 'helpers/ImageManager'
// components
import { Avatar as AvatarComponent } from '@vizeat/components/es6/components/Avatar'
// redux
import { useSelector } from 'react-redux'
import { getUserAvatar } from 'redux/selectors'

Avatar.propTypes = {
  avatar: PropTypes.shape({ id: PropTypes.number }),
  user: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    avatar: PropTypes.oneOfType([PropTypes.immutable.record, PropTypes.number, () => null]),
  }),
  file: PropTypes.shape({ id: PropTypes.number }),
  src: PropTypes.string,
  size: PropTypes.number,
}

Avatar.defaultProps = {
  avatar: undefined,
  file: undefined,
  size: 70,
  src: null,
  user: undefined,
}

export function Avatar({ user, avatar, size, file, src }) {
  const userAvatar = useSelector((state) => getUserAvatar(state, user?.id))
  const fileId = file?.id || avatar?.id || user?.avatar?.id || userAvatar?.id || null
  const image = src || (fileId ? scaleCropFile({ fileId, size: `${2 * size}x${2 * size}` }) : null)
  const firstname = user?.firstname

  return <AvatarComponent image={image} alt={image && firstname} letter={firstname?.slice(0, 2)} size={size} />
}
