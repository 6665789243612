import settings from 'settings'
import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { urlFactory } from 'helpers/url'

const AWIN = 'awc'
const AFFILIATION_ID = 'affid'
const BRAND = 'brand'
const URCHIN_TRAFFIC_MONITOR_CAMPAIGN = 'utm_campaign'
const URCHIN_TRAFFIC_MONITOR_CONTENT = 'utm_content'
const URCHIN_TRAFFIC_MONITOR_MEDIUM = 'utm_medium'
const URCHIN_TRAFFIC_MONITOR_SOURCE = 'utm_source'

const trackingQueryKeys = [
  AFFILIATION_ID,
  BRAND,
  URCHIN_TRAFFIC_MONITOR_CAMPAIGN,
  URCHIN_TRAFFIC_MONITOR_CONTENT,
  URCHIN_TRAFFIC_MONITOR_MEDIUM,
  URCHIN_TRAFFIC_MONITOR_SOURCE,
  AWIN,
]

const EW_DOMAINS = ['eatwith.com', 'vizeater.co', process.env.VERCEL_URL, settings.domainName]

export function useTrackingQueryStrings() {
  const router = useRouter()
  const trackingQueryStrings = useMemo(
    () =>
      Object.entries(router.query).reduce(
        (acc, [key, value]) => (trackingQueryKeys.includes(key) && !!value ? { ...acc, [key]: value } : acc),
        {},
      ),
    [router.query],
  )

  const getURLObjectWithTrackingParameters = useCallback(
    (url) => {
      try {
        const givenURLWithoutOrigin = url
          .replace(/(^\w+:|^)\/\//, '')
          .split('/')
          .map((subPath) => {
            const queryParameters = subPath.split('?')[1]
            const doesSubPathContainsDomainName = EW_DOMAINS.some((domain) => subPath.includes(domain))
            if (queryParameters && doesSubPathContainsDomainName) return `?${queryParameters}`
            if (!doesSubPathContainsDomainName) return subPath
            return undefined
          })
          .filter(Boolean)
          .join('/')
        const completeURL = new URL(`${urlFactory.web.canonical()}/${givenURLWithoutOrigin}`)
        const searchParams = new URLSearchParams(completeURL.search)
        const givenURLQuery = Array.from(searchParams).reduce(
          (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
          {},
        )

        return { pathname: completeURL.pathname, query: { ...trackingQueryStrings, ...givenURLQuery } }
      } catch (e) {
        return url
      }
    },
    [trackingQueryStrings],
  )

  return useCallback(
    (url) => {
      if (typeof url === 'string') {
        const doesUrlContainsSubdomains =
          url.split('.').length > 2 && !url.replace(/(^\w+:|^)\/\//, '').startsWith('www')
        const doesUrlBelongsToEWDomains = EW_DOMAINS.some((domain) => url.includes(domain))
        const isSameDomainURL = doesUrlBelongsToEWDomains && !doesUrlContainsSubdomains
        const isPath = url.startsWith('/')
        if (isSameDomainURL || isPath) return getURLObjectWithTrackingParameters(url)
        return url
      }

      if (url?.pathname || url?.query) return { ...url, query: { ...trackingQueryStrings, ...url.query } }

      return url
    },
    [getURLObjectWithTrackingParameters, trackingQueryStrings],
  )
}
