import { screenApi } from 'apis'

export async function getEvent(id, query) {
  const { data } = await screenApi.get(`/events/${id}`, { params: query })
  return data.event
}

export async function getEventPricing(id, { seats = 1, promoCode = undefined, date = '', ...query } = {}) {
  const { data } = await screenApi.get(`/events/${id}/pricing`, {
    params: {
      seats,
      date,
      promo_code: promoCode,
      ...query,
    },
  })

  return data
}

export async function getEventPlace(id) {
  const { data } = await screenApi.get(`/events/${id}/place`)
  return data
}

export async function getEventFiles(id, query) {
  const { data } = await screenApi.get(`/events/${id}/files`, { params: query })
  return data.files
}
