// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
// components
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Input } from '@vizeat/components/es6/components/Input'
import { CopyButton } from 'components/shared/buttons'

export function CopyLinkInput({ link, ...props }) {
  const { t } = useTranslation()

  return (
    <Grid
      gap='16px'
      templateColumns='2fr 1fr'
      css={`
        div[class^='Input__Container'] {
          margin-top: 0;
        }
      `}
    >
      <Input readOnly value={link} />
      <CopyButton
        value={link}
        green
        cta={t('Inbox::Booking::Copy link')}
        ctaActive={t('Inbox::Booking::Copied!')}
        {...props}
      />
    </Grid>
  )
}

CopyLinkInput.propTypes = {
  link: PropTypes.string.isRequired,
}
