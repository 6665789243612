// libs
import React, { useState, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'helpers/proptypes'
import { useLocale } from 'hooks/locales/useLocale'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
// helpers
import { fetchAutocomplete } from 'helpers/places'
// components
import Downshift from 'downshift'
import { Input } from '@vizeat/components/es6/components/Input'
import { Box } from '@vizeat/components/es6/components/Box'

const Item = styled(Box)`
  &:hover {
    background: ${({ theme }) => theme.colors.lightGray};
    cursor: pointer;
  }
`

export const AddressAutoCompleteInput = forwardRef(
  ({ error, isRequired, label, name, placeholder, onBlur, onChange, onSelect, type, value }, ref) => {
    const { locale } = useLocale()

    const [items, setItems] = useState([])
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState(value)

    useImperativeHandle(ref, () => ({
      reinitializeInput: () => {
        setInputValue()
        setMenuIsOpen(false)
      },
    }))

    const fetchAddresses = debounce(async (value) => {
      const items = value ? await fetchAutocomplete({ query: value, type, language: locale }) : []
      setMenuIsOpen(true)
      setItems(items)
    }, 500)

    function handleBlur(event) {
      // For performance issues, event cannot be accessed asynchronously, (eg. on setState callback)
      // Reference: https://reactjs.org/docs/events.html#event-pooling
      onBlur(event)
      setMenuIsOpen(false)
    }

    function handleStateChange({ inputValue, selectedItem }) {
      if (selectedItem && inputValue) return setInputValue(inputValue)
      if (!selectedItem && inputValue) return fetchAddresses(inputValue)
    }

    // called by 'onChange' function, but is actually a select action
    function handleSelect(place) {
      setItems([])
      setMenuIsOpen(false)
      onSelect(place)
    }

    function handleInputValue(inputValue) {
      setInputValue(inputValue)
      onChange(inputValue)
    }

    return (
      <Downshift
        inputValue={inputValue}
        onStateChange={handleStateChange}
        onChange={handleSelect}
        setInputValue={handleInputValue} // eslint-disable-line react/jsx-handler-names
        items={items}
        defaultSelectedItem={value}
        isOpen={menuIsOpen}
        itemToString={(item) => (item ? item.formatted : '')}
      >
        {({ getInputProps, getItemProps, isOpen }) => {
          return (
            <div>
              <Input
                name={name}
                placeholder={placeholder}
                label={label}
                error={error}
                isRequired={isRequired}
                {...getInputProps({
                  onChange: (e) => handleInputValue(e.target.value),
                  onBlur: handleBlur,
                  autoComplete: '_nope_',
                })}
              />
              {isOpen && (
                <Box boxShadow='md' borderBottom='sm' borderColor='lightGray'>
                  {items.map((item, index) => (
                    <Item bg='white' p='8px' key={index} {...getItemProps({ item })}>
                      {item.formatted}
                    </Item>
                  ))}
                </Box>
              )}
            </div>
          )
        }}
      </Downshift>
    )
  },
)

AddressAutoCompleteInput.displayName = 'AddressAutoCompleteInput'

AddressAutoCompleteInput.propTypes = {
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
}

AddressAutoCompleteInput.defaultProps = {
  error: undefined,
  isRequired: false,
  label: undefined,
  name: undefined,
  onBlur: () => {},
  onChange: () => {},
  placeholder: undefined,
  type: undefined,
  value: '',
}
