import settings from 'settings'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useDispatch } from 'react-redux'
import ReactDOM from 'react-dom'
import loadScript from 'load-script'
// components
import { Button } from '@vizeat/components/es6/components/Button'
import { Image } from '@vizeat/components/es6/components/Image'
// redux
import { loginWithGoogle } from 'redux/actions'
// styles

export function GoogleLoginButton({ children }) {
  const [disabled, setDisabled] = useState(true)
  const dispatch = useDispatch()

  const googleLoginButton = useRef(null)

  const handleLogin = useCallback(
    (googleUser) => {
      if (!googleUser || !googleUser.getBasicProfile || typeof googleUser.getBasicProfile !== 'function') return // avoid google error

      const authResponse = googleUser.getAuthResponse()
      dispatch(loginWithGoogle({ authResponse }))
    },
    [dispatch],
  )

  const initAuth2 = useCallback(() => {
    let auth2 = window.gapi.auth2.getAuthInstance()
    if (!auth2) {
      auth2 = window.gapi.auth2.init({
        client_id: `${settings.googleClientId}.apps.googleusercontent.com`,
      })
    }

    // findDOMNode must be used because the auth2 attachClickHandler take a DOM node as argument, and since this.props.as can be also a React component, it won't work anymore
    auth2.attachClickHandler(
      ReactDOM.findDOMNode(googleLoginButton.current), // eslint-disable-line
      { scope: ['email', 'profile'].join(' ') },
      handleLogin,
    )

    setDisabled(false)
  }, [handleLogin])

  const loadAuth2 = useCallback(() => {
    !window.gapi.auth2 ? window.gapi.load('auth2', initAuth2) : initAuth2()
  }, [initAuth2])

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.gapi) {
      loadScript('https://apis.google.com/js/platform.js', (err) => {
        if (!err) loadAuth2()
      })
    } else loadAuth2()
  }, [loadAuth2])

  return (
    <Button
      ref={googleLoginButton}
      disabled={disabled}
      // Unfortunately must overload values from component-library...
      css={`
        background: ${({ theme }) => theme.colors.googleBlue};
        &:hover:not([disabled]) {
          background: ${({ theme }) => theme.colors.googleBlue};
          filter: brightness(120%);
        }
      `}
      onClick={handleLogin}
    >
      <Image
        width='16px'
        heigth='16px'
        src='/assets/images/icon-button-google.svg'
        alt='Google login'
        css={{ marginRight: '8px' }}
      />
      {children}
    </Button>
  )
}

GoogleLoginButton.propTypes = {
  children: PropTypes.node,
}

GoogleLoginButton.defaultProps = {
  children: undefined,
}
