import { toMomentDate } from 'helpers/date'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { emptyRequest } from 'schemas/request'
import { emptySchedule, create as createSchedule } from 'schemas/schedule'

// getters

// The requestable_event_ids field will be dropped from the API and should be considered as closed
export function legacyIsRequestable(schedule, eventId) {
  if (!schedule) return false
  return schedule.requestable_event_ids.includes(Number(eventId))
}

export function isClosed(schedule, eventId) {
  if (!schedule) return true
  return schedule.closed_event_ids.includes(Number(eventId)) || legacyIsRequestable(schedule, eventId)
}

export function isOpened(schedule, eventId) {
  if (!schedule) return false
  return schedule.opened_event_ids.includes(Number(eventId))
}

export function isBookable(schedule, eventId) {
  if (!schedule) return false
  return schedule.bookable_event_ids.includes(Number(eventId)) && !legacyIsRequestable(schedule, eventId)
}

function getBookedSeats(schedule, eventId) {
  return schedule?.booked_seats[eventId] ?? 0
}

function isInstantBookable(schedule, eventId) {
  if (!schedule) return false
  return schedule.instant_booking_event_ids.includes(eventId)
}

function getEventScheduleMaxSeats(schedule, event) {
  const maxSeatsFromOverride = schedule.overrides[event.id]?.max_seats
  return maxSeatsFromOverride ?? event.max_seats
}

export function isSoldOut(schedule, event) {
  const { id: eventId } = event

  const bookedSeats = getBookedSeats(schedule, eventId)
  if (bookedSeats === 0 || !isOpened(schedule, eventId)) return false

  const maxSeats = getEventScheduleMaxSeats(schedule, event)
  return bookedSeats === maxSeats
}

function getSeatsLeft(schedule, event) {
  if (!schedule.date) return 0

  const maxSeats = getEventScheduleMaxSeats(schedule, event)
  if (maxSeats === 0) return 0

  const bookedSeats = getBookedSeats(schedule, event.id)
  return maxSeats - bookedSeats
}

// hooks
export function useGetScheduleForDateSelector(date) {
  return useCallback(
    ({ schedule, bookings, requests }) => {
      const selectedSchedule = schedule?.find((s) => s.date === date)
      if (!selectedSchedule)
        return {
          ...emptySchedule,
          bookings: [],
          requests: [],
        }

      const scheduleBookings = bookings.filter(
        (booking) => selectedSchedule.booking_ids.includes(booking.id) && booking.groupStatus === 'successful',
      )

      const scheduleRequests = requests.filter((request) => selectedSchedule.request_ids.includes(request.id))

      return {
        ...createSchedule(selectedSchedule),
        bookings: scheduleBookings,
        requests: scheduleRequests,
      }
    },
    [date],
  )
}

export function useGetEventScheduleSeatsLeftForDateSelector(event, date) {
  const getScheduleForDate = useGetScheduleForDateSelector(date)
  return useCallback((data) => getSeatsLeft(getScheduleForDate(data), event), [event, getScheduleForDate])
}

export function useGetEventScheduleBookedSeatsForDateSelector(eventId, date) {
  const getScheduleForDate = useGetScheduleForDateSelector(date)
  return useCallback((data) => getBookedSeats(getScheduleForDate(data), eventId), [eventId, getScheduleForDate])
}

export function useGetEventScheduleIsInstantBookableSelector(eventId, date) {
  const getScheduleForDate = useGetScheduleForDateSelector(date)
  return useCallback((data) => isInstantBookable(getScheduleForDate(data), eventId), [eventId, getScheduleForDate])
}

export function useGetEventScheduleIsSoldOutSelector(event, date) {
  const getScheduleForDate = useGetScheduleForDateSelector(date)
  return useCallback((data) => isSoldOut(getScheduleForDate(data), event), [event, getScheduleForDate])
}

export function useGetEventScheduleOpenDatesSelector(eventId, startDate, maxSize) {
  const mStartDate = toMomentDate(startDate)
  const mEndDate = mStartDate?.clone().add(7, 'days')

  return useCallback(
    ({ schedule }) => {
      const bookableEvents = [...schedule]
        .sort((a, b) => toMomentDate(a.date).diff(toMomentDate(b.date)))
        .filter((s) => {
          const isEventBookable = isBookable(s, eventId)
          if (!mStartDate) return isEventBookable

          return isEventBookable && toMomentDate(s.date).isBetween(mStartDate, mEndDate, 'days', '[]')
        })

      return maxSize ? bookableEvents.slice(0, maxSize) : bookableEvents
    },
    [eventId, mStartDate, maxSize, mEndDate],
  )
}

export function useCurrentUserEventRequestSelector(eventId, date) {
  const currentUser = useSelector(getCurrentUser)
  const getScheduleForDate = useGetScheduleForDateSelector(date)

  return useCallback(
    (data) => {
      const schedule = getScheduleForDate(data)
      if (schedule.requests.length === 0) return emptyRequest

      return (
        [...schedule.requests]
          .sort((a, b) => toMomentDate(a.created_at).diff(toMomentDate(b.created_at)))
          .find(
            (request) =>
              request.user_id === currentUser.id &&
              request.event_id === eventId &&
              !request.canceled_at &&
              !request.expired_at,
          ) || emptyRequest
      )
    },
    [currentUser.id, eventId, getScheduleForDate],
  )
}
