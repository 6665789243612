import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIsCurrentUserLoggedIn } from 'redux/selectors'
import { logout } from 'redux/actions'
import { useToggle } from '@vizeat/components/es6/hooks/useToggle/useToggle'
import { usePreventBodyScroll } from 'hooks/usePreventBodyScroll'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { ButtonIconWrapper } from '@vizeat/components/es6/components/Icon'
import IconMenuMobile from '@vizeat/components/es6/assets/icons/IconMenuMobile'
import { commonHeaderPropTypes, commonHeaderDefaultProps } from '../styled'
import { InboxMenuItem } from '../InboxMenuItem'
import { NotificationsDropdown } from '../NotificationsDropdown'
import { Header } from './Header'
import { Body } from './Body'
import { Button } from '@vizeat/components/es6/components/Button'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { MobileModal } from '@vizeat/components/es6/components/MobileModal'
import { StyledMenu } from './styled'

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1030;
  background: ${({ theme }) => theme.colors.black};
  opacity: 0.5;
  cursor: pointer;
`

const NavWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  list-style: none;
`

function Menu({ children, handleMegaMenuClosure, megaMenuActiveIndex, onHide, show }) {
  const isTabletOrHigher = useMediaQuery('tablet')

  if (isTabletOrHigher)
    return (
      <StyledMenu show={show}>
        <Header
          handleMegaMenuClosure={handleMegaMenuClosure}
          isMegaMenuOpen={!!megaMenuActiveIndex}
          toggleMenuVisibility={onHide}
        />
        {children}
      </StyledMenu>
    )

  return (
    <MobileModal
      show={show}
      onHide={onHide}
      variant='default'
      Header={
        <Header
          handleMegaMenuClosure={handleMegaMenuClosure}
          isMegaMenuOpen={!!megaMenuActiveIndex}
          showCloseIcon={false}
          toggleMenuVisibility={onHide}
        />
      }
    >
      {children}
    </MobileModal>
  )
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  handleMegaMenuClosure: PropTypes.func.isRequired,
  megaMenuActiveIndex: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

Menu.defaultProps = {
  megaMenuActiveIndex: undefined,
}

export function MobileNav({
  hideAllNavItems,
  hideExperiences,
  hidePrivateEvent,
  hideResumeApplication,
  onMenuToggle,
  shouldDisplayBecomeAHostButton,
  isTransparent,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)
  const [megaMenuActiveIndex, setMegaMenuActiveIndex] = useState()
  const [isMenuOpen, { toggle: toggleMenuVisibility }] = useToggle()
  const sidebarBodyRef = useRef(null)
  usePreventBodyScroll(isMenuOpen)

  useEffect(() => {
    onMenuToggle(isMenuOpen)
  }, [isMenuOpen, onMenuToggle])

  useEffect(() => {
    sidebarBodyRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [megaMenuActiveIndex])

  function handleMegaMenuClosure() {
    setMegaMenuActiveIndex()
  }

  return (
    <>
      {isMenuOpen && <Overlay onClick={toggleMenuVisibility} />}
      <NavWrapper>
        {!hideAllNavItems && (
          <>
            <InboxMenuItem as='li' transparent={isTransparent} />
            <NotificationsDropdown as='li' />
          </>
        )}

        <li>
          <Flex
            as={ButtonIconWrapper}
            display='flex'
            alignItems='center'
            justifyContent='center'
            onClick={toggleMenuVisibility}
          >
            <IconMenuMobile size='24px' color={isTransparent ? 'white' : 'mediumGray'} />
          </Flex>

          <Menu
            handleMegaMenuClosure={handleMegaMenuClosure}
            megaMenuActiveIndex={megaMenuActiveIndex}
            onHide={toggleMenuVisibility}
            show={isMenuOpen}
          >
            <Body
              hideAllNavItems={hideAllNavItems}
              hideExperiences={hideExperiences}
              hidePrivateEvent={hidePrivateEvent}
              hideResumeApplication={hideResumeApplication}
              megaMenuActiveIndex={megaMenuActiveIndex}
              ref={sidebarBodyRef}
              shouldDisplayBecomeAHostButton={shouldDisplayBecomeAHostButton}
              setMegaMenuActiveIndex={setMegaMenuActiveIndex}
            />

            {!megaMenuActiveIndex && isCurrentUserLoggedIn && (
              <Button secondary onClick={() => dispatch(logout())}>
                {t('Header::Logout')}
              </Button>
            )}
          </Menu>
        </li>
      </NavWrapper>
    </>
  )
}

MobileNav.propTypes = {
  ...commonHeaderPropTypes,
  shouldDisplayBecomeAHostButton: PropTypes.bool.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
}

MobileNav.defaultProps = commonHeaderDefaultProps
