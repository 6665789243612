import * as yup from 'yup'
import { currencySchema } from './currency'
import { cast } from 'helpers/schema'

const ReferralWalletSchema = yup.object({
  amount: yup.number().required(),
  currency: currencySchema,
})

function create(data) {
  return cast(ReferralWalletSchema, data)
}

export const emptyReferralWallet = create()
