import React from 'react'
import PropTypes from 'helpers/proptypes' // components
import { CategoryTitle } from './CategoryTitle'
import { ResultItem } from './ResultItem'
// helpers
import { useTranslation } from 'next-i18next'
import { sanitize } from 'helpers/string'
import { Text } from '@vizeat/components/es6/components/Text'
import { Box } from '@vizeat/components/es6/components/Box'

function getHighlightedHTMLString(stringToReplace, stringToMatch = '') {
  if (stringToMatch.trim().length === 0) return stringToReplace

  return stringToMatch
    .trim()
    .split(' ')
    .reduce((acc, str) => {
      if (!acc.toLowerCase().includes(str.toLowerCase())) return acc
      return acc.replace(sanitize(str), (highlighted) => `<em>${highlighted}</em>`)
    }, stringToReplace)
}

export function SearchResults({ eventResults, getItemProps, highlightedIndex, inputValue, placeResults, userResults }) {
  const { t } = useTranslation()

  return (
    <>
      {placeResults.length > 0 && (
        <>
          <CategoryTitle>{t('OpenSearchInput::Explore')}</CategoryTitle>
          <Box as='ul' p={0}>
            {placeResults.map(({ item, globalIndex }) => (
              <ResultItem
                innerHTML={getHighlightedHTMLString(item.place, inputValue)}
                isActive={highlightedIndex === globalIndex}
                key={globalIndex}
                {...getItemProps({ index: globalIndex })}
              >
                {({ formattedTitle }) => formattedTitle}
              </ResultItem>
            ))}
          </Box>
        </>
      )}

      {userResults.length > 0 && (
        <>
          <CategoryTitle>{t('OpenSearchInput::Hosts')}</CategoryTitle>
          <Box as='ul' p={0}>
            {userResults.map(({ item, globalIndex }) => (
              <ResultItem
                innerHTML={getHighlightedHTMLString(item.title, inputValue)}
                place={item.place}
                isActive={highlightedIndex === globalIndex}
                key={globalIndex}
                {...getItemProps({ index: globalIndex })}
              >
                {({ formattedTitle, place }) => (
                  <>
                    {formattedTitle} · {place}
                  </>
                )}
              </ResultItem>
            ))}
          </Box>
        </>
      )}

      {eventResults.length > 0 && (
        <>
          <CategoryTitle>{t('OpenSearchInput::Experiences')}</CategoryTitle>
          <Box as='ul' p={0}>
            {eventResults.map(({ item, globalIndex }) => (
              <ResultItem
                innerHTML={getHighlightedHTMLString(item.title, inputValue)}
                place={item.place}
                eventType={item.eventType}
                isActive={highlightedIndex === globalIndex}
                key={globalIndex}
                {...getItemProps({ index: globalIndex })}
              >
                {({ formattedTitle, EventTypeElement, place }) => (
                  <>
                    {formattedTitle}
                    <Text size='s'>
                      {EventTypeElement} · {place}
                    </Text>
                  </>
                )}
              </ResultItem>
            ))}
          </Box>
        </>
      )}
    </>
  )
}

SearchResults.propTypes = {
  eventResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  inputValue: PropTypes.string.isRequired,
  placeResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
