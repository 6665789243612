import * as yup from 'yup'
import { fileSchema } from './file'
import { currencySchema } from './currency'
import { overrideSchema, create as createOverride } from './override'
import { placeSchema } from './place'
import { userSchema } from './user'
import { cast } from 'helpers/schema'

const descriptionSchema = yup.object({
  id: yup.number().optional(),
  event_id: yup.number().required(),
  title: yup.string().nullable().default(null),
  describes: yup.string().required(),
  language_id: yup.number().required(),
  order: yup.number().default(0),
  items: yup.array().of(
    yup.object({
      id: yup.number().optional(),
      title: yup.string().nullable().default(null),
      type: yup.string().nullable().default(null),
      body: yup.string().nullable().default(null),
    }),
  ),
})

export const eventSchema = yup.object({
  available_for_partners: yup.boolean(),
  begins_at: yup.string().default(''),
  booking_deadline: yup.string().nullable().default(null),
  cancellation_deadline: yup.string().nullable().default(null),
  can_have_instant_bookings: yup.boolean().nullable().default(null),
  cover: fileSchema.default(undefined),
  cover_id: yup.number().nullable().default(null),
  currency: currencySchema,
  currency_id: yup.number().required(),
  descriptions: yup.array().of(descriptionSchema).nullable().default([]),
  duration: yup.string().nullable().default(null),
  ends_at: yup.string(),
  event_currency: currencySchema.nullable().default(null),
  event_currency_id: yup.number().required(),
  event_price: yup.number().nullable().default(null),
  files: yup.array().of(fileSchema).nullable().default([]),
  files_ids: yup.array().of(yup.number()).nullable().default([]),
  frozen_at: yup.string().nullable().default(null),
  hosted_with: yup.string().default(''),
  id: yup.number().optional(),
  tags: yup.array().of(yup.object({})).default([]),
  instant_booking: yup.boolean(),
  max_seats: yup.number(),
  masterchef_us: yup.boolean(),
  pe_booking_deadline: yup.string().nullable().default(null),
  pe_cancellation_deadline: yup.string().nullable().default(null),
  metadata: yup.object({
    euro_pricing: yup.object({
      event_price: yup.number().nullable().default(null),
    }),
  }),
  next_date: yup
    .object({
      date: yup.string().required(),
      available_seats: yup.number().required(),
      range_status: yup.string().required(),
    })
    .nullable(),
  min_seats: yup.number(),
  overrides: overrideSchema.nullable().default(null),
  price: yup.number().required(),
  place: placeSchema,
  place_id: yup.number().required(),
  allow_private_bookings: yup.boolean(),
  allow_public_bookings: yup.boolean(),
  privatized_by: yup.number().nullable().default(null),
  privatized_at: yup.string().nullable().default(null),
  properties: yup.object({}).default({}),
  published_at: yup.string().nullable().default(null),
  status: yup.string(),
  type: yup.string(),
  localized_type: yup.string(),
  user: userSchema,
  user_id: yup.number().required(),
  webpage_url: yup.string(),
  parent_id: yup.number().nullable().default(null),
  created_at: yup.string().nullable().default(null),
})

export function create(raw = {}) {
  const { overrides, ...data } = raw
  return {
    ...cast(eventSchema, data),
    ...(overrides && {
      overrides: createOverride(overrides),
    }),
  }
}

export const emptyEvent = create()
