import React from 'react'
import styled, { css } from 'styled-components'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { SmartLink } from 'components/shared/SmartLink'

const HEADER_HEIGHT = '52px'
export const FOOTER_HEIGHT = '34px'

export const linkBaseStyle = css`
  color: ${({ theme }) => theme.colors.mediumGray};
  padding: 0;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};

  &:hover:not([disabled]) {
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`

export const MenuItemsList = styled((props) => <Flex as='ul' {...props} />)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const MenuItem = styled(SmartLink)`
  display: flex;
  align-items: center;
  width: 100%;
  ${linkBaseStyle}
`

export const BodyWrapper = styled.nav`
  padding: 0 16px ${({ shouldDiplayLogoutButton }) => (shouldDiplayLogoutButton ? FOOTER_HEIGHT : '0')} 16px;
  height: calc(100% - ${FOOTER_HEIGHT});
  height: ${({ shouldDiplayLogoutButton }) => (shouldDiplayLogoutButton ? `calc(100% - ${FOOTER_HEIGHT})` : '100%')};
  overflow: auto;

  ${({ theme }) => theme.media.tablet`
    padding: 32px 0 0 0;
    height: ${({ shouldDiplayLogoutButton }) =>
      shouldDiplayLogoutButton
        ? `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`
        : `calc(100vh - ${HEADER_HEIGHT})`};
  `}
`

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
  padding: 24px;
  z-index: 1039;
  transition: transform 0.25s ease;
  transform: translate(${({ show }) => (show ? '-100vw' : '0')});

  ${({ theme }) => theme.media.tablet`
    width: 50vw;
    right: -50vw;
    transform: translate(${({ show }) => (show ? '-50vw' : '0')});
  `}
`
