import React from 'react'
import PropTypes from 'helpers/proptypes'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
} from 'react-share'

export function SocialShareButtons({
  disabled,
  hideFacebook,
  hideEmail,
  hideTwitter,
  hideWhatsapp,
  text,
  url,
  onShare,
}) {
  return (
    <>
      {!hideFacebook && (
        <FacebookShareButton quote={text} url={url} disabled={disabled} beforeOnClick={onShare('facebook')}>
          <FacebookIcon size={50} round />
        </FacebookShareButton>
      )}
      {!hideTwitter && (
        <TwitterShareButton title={text} url={url} disabled={disabled} beforeOnClick={onShare('twitter')}>
          <TwitterIcon size={50} round />
        </TwitterShareButton>
      )}
      {!hideWhatsapp && (
        <WhatsappShareButton title={text} url={url} disabled={disabled} beforeOnClick={onShare('whatsapp')}>
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
      )}
      {!hideEmail && (
        <EmailShareButton size={50} body={text} disabled={disabled} openWindow beforeOnClick={onShare('email')}>
          <img src='/assets/images/share-email.svg' alt='Share text via email' />
        </EmailShareButton>
      )}
    </>
  )
}

SocialShareButtons.propTypes = {
  disabled: PropTypes.bool,
  hideEmail: PropTypes.bool,
  hideFacebook: PropTypes.bool,
  hideTwitter: PropTypes.bool,
  hideWhatsapp: PropTypes.bool,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onShare: PropTypes.func,
}

SocialShareButtons.defaultProps = {
  disabled: false,
  hideEmail: false,
  hideFacebook: false,
  hideTwitter: false,
  hideWhatsapp: false,
  onShare: () => () => {},
}
