import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  getBookableSchedules,
  getNonBookableSchedules,
  getNonOpenedSchedules,
  getOpenedSchedules,
  getSoldOutSchedules,
  scheduleIsSoldOut,
} from 'redux/selectors'
// components
import { CalendarPicker } from 'components/shared/inputs'
import { MediaQuery } from '@vizeat/components/es6/components/MediaQuery'

const mapStateToProps = (state, props) => {
  return {
    fromStore: {
      bookableSchedules: getBookableSchedules(state, props.eventId),
      nonBookableSchedules: getNonBookableSchedules(state, props.eventId),
      nonOpenedSchedules: getNonOpenedSchedules(state, props.eventId),
      isScheduleSoldOut: (mDate) => scheduleIsSoldOut(state, mDate, props.eventId),
      openedSchedules: getOpenedSchedules(state, props.eventId),
      soldOutSchedules: getSoldOutSchedules(state, props.eventId),
    },
  }
}

class _EventCalendarPicker extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    eventId: PropTypes.number.isRequired, // used in mapStateToProps
    fromStore: PropTypes.shape({
      bookableSchedules: PropTypes.immutable.map.isRequired,
      nonBookableSchedules: PropTypes.immutable.map.isRequired,
      isScheduleSoldOut: PropTypes.func.isRequired,
      nonOpenedSchedules: PropTypes.immutable.map.isRequired,
      openedSchedules: PropTypes.immutable.map.isRequired,
      soldOutSchedules: PropTypes.immutable.map.isRequired,
    }).isRequired,
    handleDayClick: PropTypes.func.isRequired,
    handleDayHover: PropTypes.func,
    initialMonth: PropTypes.moment,
    modifiers: PropTypes.shape({}),
    onMonthChange: PropTypes.func.isRequired,
    privatize: PropTypes.bool,
    selected: PropTypes.moment,
    selectedDays: PropTypes.arrayOf(PropTypes.moment),
  }

  static defaultProps = {
    handleDayHover: () => {},
    initialMonth: moment.utc().date(15),
    privatize: false,
    selected: undefined,
    selectedDays: undefined,
    modifiers: undefined,
  }

  state = {
    hovered: undefined,
  }

  handleDayClick = (mDate, modifiers) => {
    if (modifiers.outside || modifiers.closed || modifiers.disabled) return
    return this.props.handleDayClick(mDate, modifiers)
  }

  handleDayMouseEnter = (mDate, modifiers) => {
    if (modifiers.outside || modifiers.disabled) return
    this.setState({ hovered: mDate }, this.props.handleDayHover(mDate))
  }

  handleDayMouseLeave = () => {
    this.setState({ hovered: undefined }, this.props.handleDayHover(undefined))
  }

  render() {
    const {
      fromStore: {
        bookableSchedules,
        nonBookableSchedules,
        isScheduleSoldOut,
        nonOpenedSchedules,
        openedSchedules,
        soldOutSchedules,
      },
      initialMonth,
      onMonthChange,
      privatize,
      selected,
      selectedDays,
    } = this.props
    const { hovered } = this.state

    const defaultModifiers = {
      selected,
      hovered,
      closed: (privatize ? nonBookableSchedules : nonOpenedSchedules).map(({ date }) => moment.utc(date)).toArray(),
      opened: privatize ? undefined : openedSchedules.map(({ date }) => moment.utc(date)).toArray(),
      soldout: privatize ? undefined : soldOutSchedules.map(({ date }) => moment.utc(date)).toArray(),
      disabled: (mUtcDate) =>
        mUtcDate.isBefore(moment.utc(), 'day') ||
        // A date might not be bookable if it's closed, sold-out, privatized or booking deadline is past
        // We want to show as disabled the dates that are no longer bookable because booking deadline
        // is past, but not dates that are soldout to create FOMO
        // Dates that have alternative events should not be shown as disabled
        (!bookableSchedules.has(mUtcDate.format('YYYY-MM-DD')) && !isScheduleSoldOut(mUtcDate)),
    }

    const modifiers = this.props.modifiers || defaultModifiers

    return (
      <MediaQuery minWidth='tablet'>
        {(matches) => (
          <CalendarPicker
            key={`${matches}-${initialMonth.format('YYYY-MM-DD')}`} // Force rerender to ensure correct number of months and initial month when it's autocompleted...
            fromMonth={moment.utc()}
            modifiers={modifiers}
            initialMonth={initialMonth}
            numberOfMonths={matches ? 1 : 2}
            onDayClick={this.handleDayClick}
            onDayMouseEnter={this.handleDayMouseEnter}
            onDayMouseLeave={this.handleDayMouseLeave}
            onMonthChange={onMonthChange}
            selectedDays={selectedDays}
          />
        )}
      </MediaQuery>
    )
  }
}

export const LegacyEventCalendarPicker = connect(mapStateToProps, null)(_EventCalendarPicker)
