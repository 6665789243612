import { screenApi } from 'apis'

export async function getNotifications(offset) {
  const { data } = await screenApi.get(`/notifications`, { params: { offset } })
  const { notifications, ...metadata } = data
  return { notifications, metadata }
}

export async function setNotificationAsRead(id) {
  return await screenApi.patch(`/notifications/${id}`)
}

export async function markAllNotificationsAsRead() {
  return await screenApi.patch(`/notifications`)
}
