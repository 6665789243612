import { useInfiniteQuery } from '@tanstack/react-query'
import { getNotifications } from 'api/notifications'
import { STALE_TIME } from 'helpers/query'
import { emptyNotifications } from 'schemas/notifications'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { screenApi } from 'apis'

export const COUNT = 10

export const getNotificationsQueryKey = (userId) => ['notifications', 'userId', userId]

export function useNotificationsQuery(reactQueryOptions) {
  const currentUser = useSelector(getCurrentUser)

  const notificationsQueryResult = useInfiniteQuery({
    staleTime: STALE_TIME,
    placeholderData: { pages: [emptyNotifications], pageParms: [] },
    queryKey: getNotificationsQueryKey(currentUser.id),
    queryFn: ({ pageParam }) => getNotifications(pageParam?.offset || 0),
    getNextPageParam: (lastPage) => {
      const { notifications, metadata } = lastPage
      const offset = parseInt(metadata.offset, 10)
      const total = parseInt(metadata.total, 10)
      if (offset + notifications.length >= total) return undefined
      return { offset: offset + COUNT }
    },
    select: ({ pages }) => ({
      notifications: pages.reduce((acc, { notifications }) => [...acc, ...notifications], []),
      metadata: pages[pages.length - 1].metadata,
    }),
    enabled: !!currentUser.id && !!screenApi.getHeader('authorization'),
    ...reactQueryOptions,
  })

  function selectData(selector) {
    return selector(notificationsQueryResult.isSuccess ? notificationsQueryResult.data : emptyNotifications)
  }

  return {
    notificationsQueryResult,
    selectData,
  }
}
