import { screenApi } from 'apis'
import { isMapSearch } from 'helpers/search'

export async function fetchSearch(payload = {}, { allowPublicBookings, canUseBounds = true } = {}) {
  const shouldUseBounds = canUseBounds && !payload.q && !payload.type && !isMapSearch(payload)

  let sanitizedPayload = Object.entries(payload).filter(([, value]) => typeof value !== 'undefined' && value !== null)
  sanitizedPayload = Object.fromEntries(sanitizedPayload)

  const { data } = await screenApi.post('/search', {
    ...(isMapSearch(payload) && {
      type: 'map',
    }),
    ...(shouldUseBounds && {
      type: 'bounds',
      useGeoLeveling: false,
      sortBy: 'host_relevance',
    }),
    ...sanitizedPayload,
    allowPublicBookings: payload.allowPublicBookings ?? allowPublicBookings,
  })

  return data
}

export async function fetchSearchAutoComplete(query = {}) {
  const { data } = await screenApi.get('/search/autocomplete', {
    params: query,
  })

  return data
}
