import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import moment from 'moment'
// components
import { Button } from '@vizeat/components/es6/components/Button'
import { Plural } from 'components/shared/Plural'
import { CategoryTitle } from './CategoryTitle'
import { ResultItem } from './ResultItem'
// helpers
import { COOKIE_KEYS, deleteCookie } from 'helpers/cookies'
import { Box } from '@vizeat/components/es6/components/Box'

const ClearHistoryButton = styled(Button)`
  font-size: 12px;
  margin-left: 8px;
  padding: 0;
`

function FormattedText({ label, search }) {
  const { datefrom, dateto, nbguests } = search
  const from = datefrom && moment.utc(datefrom).format('ll')
  const to = dateto && moment.utc(dateto).format('ll')

  return (
    <span>
      {label}
      {from && ` · ${from}`}
      {to && from !== to && ` - ${to}`}
      {nbguests && ' · '}
      {nbguests && (
        <Plural
          singularKey={__('OpenSearchInput::1 guest')}
          pluralKey={__('OpenSearchInput::{{count}} guests')}
          renderCount={parseInt(nbguests, 10)}
        />
      )}
    </span>
  )
}

FormattedText.propTypes = {
  label: PropTypes.string.isRequired,
  search: PropTypes.shape({
    dateto: PropTypes.string,
    datefrom: PropTypes.string,
    nbguests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
}

export function RecentSearches({ getItemProps, highlightedIndex, recentSearches, onRecentSearchClick }) {
  const { t } = useTranslation()

  function handleClearHistory() {
    deleteCookie(COOKIE_KEYS.RECENT_SEARCHES)
  }

  if (!recentSearches.length) return null

  return (
    <>
      <CategoryTitle>
        {t('OpenSearchInput::Recent searches')}
        <ClearHistoryButton type='button' clear onClick={handleClearHistory}>
          {t('OpenSearchInput::(Clear)')}
        </ClearHistoryButton>
      </CategoryTitle>

      {recentSearches.length > 0 && (
        <Box as='ul' p={0}>
          {recentSearches.map(({ item }, index) => {
            const { onClick, ...props } = getItemProps({ index, item })
            return (
              <ResultItem
                key={index}
                isActive={highlightedIndex === index}
                onClick={(e) => {
                  onClick(e)
                  onRecentSearchClick?.()
                }}
                {...props}
              >
                {() => <FormattedText label={item.q} search={item} />}
              </ResultItem>
            )
          })}
        </Box>
      )}
    </>
  )
}

RecentSearches.propTypes = {
  onRecentSearchClick: PropTypes.func,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  recentSearches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

RecentSearches.defaultProps = {
  onRecentSearchClick: undefined,
}
