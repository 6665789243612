import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const fileSchema = yup.object({
  id: yup.number().optional(),
  account_id: yup.number().required(),
  path: yup.string().required(),
  attachable_id: yup.number().required(),
  attachable_type: yup.string().required(),
  category: yup.string(),
  order: yup.number().default(0),
  show: yup.boolean().nullable().default(null),
})

export function create(data) {
  return cast(fileSchema, data)
}

export const emptyFile = create()
