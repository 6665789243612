import * as yup from 'yup'
import { languageSchema } from './language'
import { cast } from 'helpers/schema'

export const accountSchema = yup.object({
  id: yup.number().optional(),
  email: yup.string(),
  facebook_id: yup.number().nullable().default(null),
  facebook_token: yup.string().nullable().default(null),
  google_id: yup.number().nullable().default(null),
  language: languageSchema,
  language_id: yup.number(),
  oauth_token: yup.string().nullable().default(null),
  stripe_account_id: yup.number().nullable().default(null),
  stripe_customer_id: yup.number().nullable().default(null),
  validation_token: yup.string().nullable().default(null),
  validated_at: yup.string().nullable().default(null),
  created_at: yup.string(),
  updated_at: yup.string(),
  deactivated_at: yup.string().nullable().default(null),
})

export function create(data) {
  return cast(accountSchema, data)
}

export const emptyAccount = create()
