import { useQuery } from '@tanstack/react-query'
import { getEventDaySchedule, getEventMonthSchedules, getEventNextSchedule, getSchedule } from 'api/schedules'
import { toMomentDate } from 'helpers/date'
import { STALE_TIME } from 'helpers/query'
import {
  useGetEventScheduleIsInstantBookableSelector,
  useGetEventScheduleBookedSeatsForDateSelector,
  useGetEventScheduleSeatsLeftForDateSelector,
  useGetScheduleForDateSelector,
  useGetEventScheduleOpenDatesSelector,
  useCurrentUserEventRequestSelector,
  useGetEventScheduleIsSoldOutSelector,
} from 'hooks/queries/schedule'
import moment, { isMoment } from 'moment'
import { useRouter } from 'next/router'
import { emptyRequest } from 'redux/schemas'
import { emptySchedule } from 'schemas/schedule'

const emptyScheduleData = {
  schedule: [],
  bookings: [],
  requests: [],
  events: [],
}

export function getEventNextScheduleQueryKey(eventId) {
  return ['planning', String(eventId), 'next']
}

export function getEventDayScheduleQueryKey(eventId, date) {
  return ['planning', String(eventId), date]
}

export function getEventMonthSchedulesQueryKey(eventId, start, end) {
  const queryKey = ['planning', String(eventId), start]
  if (end) queryKey.push(end)
  return queryKey
}

function useDefaultDate(providedDate) {
  const { query } = useRouter()
  const date = providedDate || query.date
  return isMoment(date) ? date.format('YYYY-MM-DD') : date
}

export function useSchedulesQuery(mDate, reactQueryOptions) {
  const formattedDate = mDate.format('YYYY-MM')

  return useQuery({
    staleTime: STALE_TIME,
    queryKey: ['planning', formattedDate],
    queryFn: () => getSchedule(formattedDate),
    ...reactQueryOptions,
  })
}

export function useEventNextScheduleQuery(eventId, reactQueryOptions) {
  return useQuery({
    staleTime: STALE_TIME,
    queryKey: getEventNextScheduleQueryKey(eventId),
    queryFn: () => getEventNextSchedule(eventId),
    ...reactQueryOptions,
  })
}

export function useEventMonthSchedulesFromRangeQuery(
  eventId,
  { startDate = moment.utc(), endDate },
  reactQueryOptions,
) {
  const start = toMomentDate(startDate) ?? moment.utc()
  const formattedStartDate = start.format('YYYY-MM')
  const formattedEndDate = toMomentDate(endDate)?.format('YYYY-MM')

  return useQuery({
    staleTime: STALE_TIME,
    placeholderData: emptyScheduleData,
    queryKey: getEventMonthSchedulesQueryKey(eventId, formattedStartDate, formattedEndDate),
    queryFn: () => getEventMonthSchedules(eventId, formattedStartDate, formattedEndDate),
    ...reactQueryOptions,
  })
}

export function useEventDayScheduleQuery(eventId, { date }, reactQueryOptions) {
  const formattedDate = toMomentDate(date).format('YYYY-MM-DD')

  return useQuery({
    staleTime: STALE_TIME,
    queryKey: getEventDayScheduleQueryKey(eventId, formattedDate),
    queryFn: () => getEventDaySchedule(eventId, formattedDate),
    ...reactQueryOptions,
  })
}

export function useEventMonthSchedulesFromDateQuery(eventId, date, reactQueryOptions) {
  return useEventMonthSchedulesFromRangeQuery(
    eventId,
    { startDate: date },
    {
      enabled: !!eventId,
      ...reactQueryOptions,
    },
  )
}

// query selector wrappers
export function useEventScheduleForDate(event, providedDate) {
  const date = useDefaultDate(providedDate)
  const getScheduleForDate = useGetScheduleForDateSelector(date)

  const { data: schedule = emptySchedule } = useEventMonthSchedulesFromDateQuery(event.id, date, {
    select: getScheduleForDate,
  })

  return schedule
}

export function useEventScheduleSeatsLeft(event, providedDate) {
  const date = useDefaultDate(providedDate)
  const getEventScheduleSeatsLeft = useGetEventScheduleSeatsLeftForDateSelector(event, date)

  const { data: seatsLeft = 0 } = useEventMonthSchedulesFromDateQuery(event.id, date, {
    select: getEventScheduleSeatsLeft,
  })

  return seatsLeft
}

export function useEventScheduleBookedSeats(eventId, providedDate) {
  const date = useDefaultDate(providedDate)
  const getEventScheduleBookedSeats = useGetEventScheduleBookedSeatsForDateSelector(eventId, date)

  const { data: bookedSeats = 0 } = useEventMonthSchedulesFromDateQuery(eventId, date, {
    select: getEventScheduleBookedSeats,
  })

  return bookedSeats
}

export function useEventScheduleIsInstantBookable(eventId, date) {
  const getEventScheduleIsInstantBookable = useGetEventScheduleIsInstantBookableSelector(eventId, date)

  const { data: isInstantBookeable = false } = useEventMonthSchedulesFromDateQuery(eventId, date, {
    select: getEventScheduleIsInstantBookable,
  })

  return isInstantBookeable
}

export function useEventScheduleIsSoldOut(event, providedDate) {
  const date = useDefaultDate(providedDate)
  const getEventScheduleIsSoldOut = useGetEventScheduleIsSoldOutSelector(event, date)

  const { data: isSoldOut = false } = useEventMonthSchedulesFromDateQuery(event.id, date, {
    select: getEventScheduleIsSoldOut,
  })

  return isSoldOut
}

export function useEventScheduleOpenDates(eventId, providedDate, maxSize) {
  const date = useDefaultDate(providedDate)
  const getEventScheduleOpenDates = useGetEventScheduleOpenDatesSelector(eventId, date, maxSize)

  const { data: openDates = [] } = useEventMonthSchedulesFromDateQuery(eventId, date, {
    select: getEventScheduleOpenDates,
  })

  return openDates
}

export function useCurrentUserEventRequest(eventId, providedDate) {
  const date = useDefaultDate(providedDate)
  const getCurrentUserEventRequest = useCurrentUserEventRequestSelector(eventId, date)

  const { data: request = emptyRequest } = useEventMonthSchedulesFromDateQuery(eventId, date, {
    select: getCurrentUserEventRequest,
  })

  return request
}
