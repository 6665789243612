import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { NotificationBadge } from '@vizeat/components/es6/components/NotificationBadge'
import { Button } from '@vizeat/components/es6/components/Button'

export const StyledNotificationBadge = styled((props) => <NotificationBadge {...props} />)`
  margin-left: 0;
`

export function MarkAllAsReadButton({ children, ...props }) {
  return (
    <Button
      clear
      css={`
        padding: 0;
        font-size: 12px;
      `}
      {...props}
    >
      {children}
    </Button>
  )
}

MarkAllAsReadButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ToggleWrapper = styled.button`
  display: flex;
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  position: relative;
  margin-right: 8px;
  padding: 0 8px;
  ${({ theme }) => theme.media.tablet`
    padding: 0 16px;
  `}
`
