import React, { useState, useCallback } from 'react'
import moment from 'moment'
import PropTypes from 'helpers/proptypes'
import { Button } from '@vizeat/components/es6/components/Button'
import { MultiDayPicker } from '@vizeat/components/es6/components/MultiDayPicker'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Text } from '@vizeat/components/es6/components/Text'
import { Divider } from '@vizeat/components/es6/components/Divider'
import IconCalendar from '@vizeat/components/es6/assets/icons/IconCalendar'
import { HorizontalDirectionChevronIcon } from 'components/shared/icons'
import { isValidDay } from 'helpers/date'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { useTranslation } from 'next-i18next'

export function SearchDatesDropdown({ from, to, onChange, onClose, Trigger, dropdownStyles, dropdownPosition }) {
  const { t } = useTranslation()
  const isTabletOrHigher = useMediaQuery('tablet')

  const [state, setState] = useState({
    hovered: undefined,
    prevFrom: undefined,
    prevTo: undefined,
    month: moment.utc(),
  })

  const handleMonthChange = useCallback((day) => {
    setState((prev) => ({
      ...prev,
      month: day,
    }))
  }, [])

  const selectThisWeek = useCallback(() => {
    const from = moment.utc().add(6, 'hours')
    setState((prev) => ({ ...prev, month: from }))
    onChange({ from, to: moment.utc().day(7) })
  }, [onChange])

  const selectThisWeekEnd = useCallback(() => {
    const from = moment.utc().day() === 6 ? moment.utc().day(7) : moment.utc().day(6)
    setState((prev) => ({ ...prev, month: from }))
    onChange({ from, to: moment.utc().day(7) })
  }, [onChange])

  const selectNextWeek = useCallback(() => {
    const from = moment.utc().day(8)
    setState((prev) => ({ ...prev, month: from }))
    onChange({ from, to: moment.utc().day(14) })
  }, [onChange])

  const onDropdownOpen = useCallback(() => {
    setState((prev) => ({ ...prev, prevFrom: from, prevTo: to }))
  }, [from, to])

  const onDropdownClose = useCallback(() => {
    if (onClose) return onClose({ from, to: to || from })
    if (from && !to) return onChange({ from, to: from }) // select one date only
    if (!from || !to) return onChange({ from: state.prevFrom, to: state.prevTo })
  }, [from, onChange, onClose, state.prevFrom, state.prevTo, to])

  return (
    <Dropdown
      id='date-dropdown'
      onOpen={onDropdownOpen}
      onClose={onDropdownClose}
      Trigger={Trigger}
      position={dropdownPosition}
      dropdownStyles={dropdownStyles}
      isDropdownOnMobile={!isTabletOrHigher}
    >
      {({ handleDropdownHide }) => (
        <Box
          position='relative'
          height={{ tablet: 'auto', default: '100%' }}
          minHeight={{ tablet: 'auto', default: '530px' }}
          bg='white'
        >
          {!isTabletOrHigher && (
            <>
              <Flex flexDirection='column' pb='16px'>
                <Flex color='mediumGray' justifyContent='flex-start' alignItems='center' onClick={handleDropdownHide}>
                  <HorizontalDirectionChevronIcon direction='backward' mr='8px' size='24px' />
                  <Text as='span'>{t('SearchMobile::Back')}</Text>
                </Flex>
                <Flex color='mediumGray' justifyContent='center' alignItems='center' mt='24px'>
                  <IconCalendar mr='8px' fontSize='16px' />
                  <Text as='span'>{t('HomePage::SearchForm::MobileOnly::DropdownLabel::When are you going?')}</Text>
                </Flex>
              </Flex>
              <Divider m='0' width='100%' color='lightGray' />
            </>
          )}

          <>
            <Flex flexDirection='row' justifyContent='space-evenly' css={{ whiteSpace: 'nowrap' }}>
              {moment.utc().day() < 5 && (
                <Button
                  clear
                  type='button'
                  onClick={selectThisWeek}
                  tabIndex={-1}
                  css={{ fontSize: '12px', padding: '12px 16px' }}
                >
                  {t('HomePage::SearchForm::CalendarMenuItem::This week')}
                </Button>
              )}
              <Button
                clear
                type='button'
                onClick={selectThisWeekEnd}
                tabIndex={-1}
                css={{ fontSize: '12px', padding: '12px 16px' }}
              >
                {t('HomePage::SearchForm::CalendarMenuItem::This week end')}
              </Button>
              <Button
                clear
                type='button'
                onClick={selectNextWeek}
                tabIndex={-1}
                css={{ fontSize: '12px', padding: '12px 16px' }}
              >
                {t('HomePage::SearchForm::CalendarMenuItem::Next week')}
              </Button>
            </Flex>
            <Divider my='0' color='lightGray' />
            <MultiDayPicker
              month={state.month}
              onMonthChange={handleMonthChange}
              disabledDays={{
                before: moment().add(6, 'hours'), // Getting compared to non UTC dates
              }}
              isValidDate={isValidDay}
              onChange={onChange}
              from={from}
              to={to}
            />
          </>

          {!isTabletOrHigher && (
            <Box position='absolute' bottom='0' right='0' left='0' width='100%' height='48px' textAlign='center'>
              <Button
                green
                fullWidth
                css={{ height: '100%', borderRadius: '0', textTransform: 'uppercase' }}
                onClick={handleDropdownHide}
                type='button'
              >
                {t('HomePage::SearchForm::MobileOnly::Select dates')}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Dropdown>
  )
}

SearchDatesDropdown.propTypes = {
  Trigger: PropTypes.node,
  from: PropTypes.moment,
  to: PropTypes.moment,
  onChange: PropTypes.func.isRequired, // ({ from, to }) => {...}
  onClose: PropTypes.func, // ({ from, to }) => {...}
  dropdownStyles: PropTypes.string,
  dropdownPosition: PropTypes.oneOf(['center', 'right']),
}

SearchDatesDropdown.defaultProps = {
  Trigger: undefined,
  from: undefined,
  to: undefined,
  onClose: undefined,
  dropdownStyles: undefined,
  dropdownPosition: undefined,
}
