import styled from 'styled-components'

export const CategoryTitle = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
