import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'

const StyledResultItem = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.text)};
  cursor: pointer;
  line-height: 20px;
  margin-bottom: 8px;
  white-space: nowrap;

  em {
    color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.darkGray)};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const EventType = styled.span`
  color: ${({ theme, type }) => {
    switch (type) {
      case 'Cooking classes':
        return theme.colors.purple
      case 'Food walk':
        return theme.colors.pink
      default:
        return theme.colors.orange
    }
  }};
`

// Must forward ref otherwise Downshift complains about it
export const ResultItem = React.forwardRef(
  ({ innerHTML, place, children, isActive, eventType, title, onClick, ...props }, ref) => {
    const formattedTitle = innerHTML ? <span dangerouslySetInnerHTML={{ __html: innerHTML }} /> : <em>{title}</em>
    const EventTypeElement = <EventType type={eventType}>{eventType}</EventType>
    // Must copy onClick function (passed via getItemProps from downshift) to onMouseDown handler
    // otherwise on native device all it does is shifting focus instead of actually selecting the item
    return (
      <StyledResultItem isActive={isActive} onMouseDown={onClick} onClick={onClick} ref={ref} {...props}>
        {children({ formattedTitle, place, EventTypeElement })}
      </StyledResultItem>
    )
  },
)

// Give this component a more helpful display name in DevTools.
ResultItem.displayName = ResultItem.displayName || ResultItem.name || ResultItem.label

ResultItem.propTypes = {
  children: PropTypes.func.isRequired,
  eventType: PropTypes.string,
  innerHTML: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string,
  place: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

ResultItem.defaultProps = {
  eventType: undefined,
  innerHTML: undefined,
  title: undefined,
  place: undefined,
}
