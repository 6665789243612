// libs
import React, { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
// components
import { Alert } from '@vizeat/components/es6/components/Alert'
import { Text } from '@vizeat/components/es6/components/Text'
import { ButtonWithLoader } from '@vizeat/components/es6/components/ButtonWithLoader'
// hooks
import useGeolocation from '@rooks/use-geolocation'
import { useGeolocationStatus } from 'hooks/geolocation/useGeolocationStatus'
import { useSafeState } from '@vizeat/components/es6/hooks'
import { useTrackingQueryStrings } from 'hooks/tracking'
// helpers
import { fetchReverse } from 'helpers/places'
import { Box } from '@vizeat/components/es6/components/Box'
import { IconSearch } from '@vizeat/components/es6/assets/icons'

export function NearbyExperiences() {
  const { t } = useTranslation()
  const router = useRouter()
  const keepTrackingQueryStrings = useTrackingQueryStrings()

  const [isLoading, setIsLoading] = useSafeState(false)
  const [hasUserClickedButton, setHasUserClickedButton] = useSafeState(false)

  const geolocationStatus = useGeolocationStatus()
  const { lat, lng, isError, message } =
    useGeolocation({ when: hasUserClickedButton || geolocationStatus === 'granted' }) || {}

  function handleClick() {
    setIsLoading(true)
    setHasUserClickedButton(true)
  }

  useEffect(() => {
    async function reverseGeocodePosition({ lat, lng }) {
      try {
        const [place] = await fetchReverse({ lat, lng, types: 'city' })
        const url = keepTrackingQueryStrings({
          pathname: '/search',
          query: { q: place.formatted, pid: place.provider_id },
        })
        if (place) router.push(url)
      } finally {
        setIsLoading(false)
      }
    }

    if (hasUserClickedButton) {
      if (lat && lng) reverseGeocodePosition({ lat, lng })
      if (isError) setIsLoading(false)
    }
  }, [hasUserClickedButton, lat, lng, isError]) // eslint-disable-line react-hooks/exhaustive-deps
  // 👆 Router methods must not be addedd here because they are not memoized and hence cause infinite rerenders if added to deps array https://github.com/vercel/next.js/issues/18127

  return (
    <Box textAlign='center' borderTop='sm' borderColor='lightGray' margin='0 14px' pt='18px'>
      <Text fontWeight='bolder'>{t('OpenSearchInput::Find experiences near me')}</Text>
      {isError && message && <Alert type='error'>{message}</Alert>}
      <ButtonWithLoader
        type='button'
        rounded
        onClick={handleClick}
        loading={isLoading}
        allowTextWrap
        disabled={!!isError}
        css={`
          font-size: 14px;
          padding: 6px 16px;
        `}
      >
        <IconSearch mr='8px' />
        {geolocationStatus === 'granted'
          ? t('OpenSearchInput::Search around me')
          : t('OpenSearchInput::Allow localization')}
      </ButtonWithLoader>
    </Box>
  )
}
