import { screenApi } from 'apis'

export async function getSchedules(date) {
  const { data } = await screenApi.get(`/planning/${date}`)
  return data.planning
}

export async function getEventNextSchedule(id) {
  const { data } = await screenApi.get(`events/${id}/planning/next`)
  return data.planning
}

export async function getEventMonthSchedules(id, start, end) {
  let url = `/events/${id}/planning/${start}`
  if (end) url += `/${end}`

  const { data } = await screenApi.get(url)
  return data.planning
}

export async function getEventDaySchedule(id, date) {
  const { data } = await screenApi.get(`/events/${id}/planning/${date}`)
  return data.planning
}
