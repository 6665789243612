import { gtmButtonClicked } from 'gtm'
import { useRef } from 'react'

export const useGTMButtonClickOnce = ({ buttonId, buttonCta }) => {
  const calledOnce = useRef(false)

  return () => {
    if (calledOnce.current) return
    gtmButtonClicked({
      buttonId,
      buttonCta,
    })
    calledOnce.current = true
  }
}
