import { useEffect, useState } from 'react'
import equal from 'deep-equal'

export default function useDebouncedState(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timerId =
      delay > 0
        ? window.setTimeout(() => {
            setDebouncedValue((prev) => {
              if (equal(prev, value)) return prev
              return value
            })
          }, delay)
        : 0

    return () => {
      clearTimeout(timerId)
    }
  }, [value, delay])

  return delay > 0 ? debouncedValue : value
}
