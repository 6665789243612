import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useNotificationsQuery } from 'hooks/queries/notifications/useNotificationsQuery'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Text } from '@vizeat/components/es6/components/Text'
import { StyledNotificationBadge, MarkAllAsReadButton } from './styled'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { NotificationItem } from './NotificationItem'
import { InfiniteScroll } from '@vizeat/components/es6/components/InfiniteScroll'
import { Divider } from '@vizeat/components/es6/components/Divider'

export function NotificationsList({ markAllAsRead, closeModal }) {
  const { t } = useTranslation()
  const isTabletOrHigher = useMediaQuery('tablet')

  const {
    selectData,
    notificationsQueryResult: { fetchNextPage, hasNextPage, isLoading },
  } = useNotificationsQuery()
  const { notifications, unread } = selectData(({ notifications, metadata }) => ({
    notifications,
    unread: metadata.unread_count,
  }))

  const canLoadMore = useMemo(() => !isLoading && hasNextPage, [hasNextPage, isLoading])

  return (
    <Flex
      flexDirection='column'
      minWidth='280px'
      position={{ tablet: 'relative', default: 'static' }}
      margin={{ tablet: '0', default: '-16px' }}
    >
      {/*  Header */}
      {isTabletOrHigher && (
        <Box p={{ tablet: '8px', default: '0' }} flex='none'>
          <Flex flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Flex flexDirection='row' gap='4px'>
              <Text as='span'>{t('Notifications::Notifications')}</Text>
              {unread > 0 && <StyledNotificationBadge>{unread}</StyledNotificationBadge>}
            </Flex>
            {unread > 0 && (
              <MarkAllAsReadButton onClick={markAllAsRead}>{t('Notifications::Mark all as read')}</MarkAllAsReadButton>
            )}
          </Flex>
        </Box>
      )}

      <Divider width='100%' my='0' color='lighterGray' />

      {/* Content */}
      <Box
        as='ul'
        m='0'
        p='0'
        height={{ tablet: '300px', default: 'auto' }}
        width={{ tablet: 'auto', default: 'auto' }}
        minWidth='320px'
        overflowY={{ tablet: 'auto', default: 'visible' }}
      >
        {notifications.map((notification) => (
          <li key={notification.id}>
            <NotificationItem
              notification={notification}
              isRead={!!notification.read_at || unread === 0}
              closeModal={closeModal}
            />
            <Divider my='0' color='lighterGray' />
          </li>
        ))}

        {notifications.length === 0 && (
          <Box textAlign='center' p='8px'>
            <Text as='span' color='darkGray'>
              {t('Notifications::You have no notifications')}
            </Text>
          </Box>
        )}

        <InfiniteScroll canLoadMore={canLoadMore} onLoadMore={fetchNextPage} />
      </Box>

      {/* Footer */}
      <Box
        position='sticky'
        bottom={{ tablet: '0', default: '-16px' }}
        p={{ tablet: '8px', default: '16px' }}
        textAlign='center'
        borderRadius='md'
        bg='white'
      >
        <Text as='span' color='mediumGray' size={isTabletOrHigher ? 'xs' : 's'}>
          {canLoadMore ? t('Notifications::Scroll to load more') : t('Notifications::All notifications loaded')}
        </Text>
      </Box>
    </Flex>
  )
}

NotificationsList.propTypes = {
  markAllAsRead: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
}

NotificationsList.defaultProps = {
  closeModal: undefined,
}
