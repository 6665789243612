import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { Button } from '@vizeat/components/es6/components/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export function CopyButton({ cta, ctaActive, onCopy, value, ...props }) {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [isCopied])

  function handleCopy() {
    setIsCopied(true)
    onCopy()
  }

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      <Button {...props}>{isCopied ? ctaActive || t('CopyButton::Copied!') : cta || t('CopyButton::Copy')}</Button>
    </CopyToClipboard>
  )
}

CopyButton.propTypes = {
  cta: PropTypes.string,
  ctaActive: PropTypes.string,
  onCopy: PropTypes.func,
  value: PropTypes.string.isRequired,
}

CopyButton.defaultProps = {
  cta: undefined,
  ctaActive: undefined,
  onCopy: () => {},
}
