import * as yup from 'yup'
import { overrideSchema } from './override'
import { cast } from 'helpers/schema'

const scheduleOverridesSchema = yup.lazy((overrides) => {
  if (!overrides) return yup.object({})

  return Object.keys(overrides).reduce(
    (acc, eventId) =>
      acc.concat(
        yup.object({
          [eventId]: overrideSchema,
        }),
      ),
    yup.object({}),
  )
})

export const scheduleSchema = yup.object({
  date: yup.string().required(),
  opened_event_ids: yup.array().of(yup.number()).default([]),
  closed_event_ids: yup.array().of(yup.number()).default([]),
  bookable_event_ids: yup.array().of(yup.number()).default([]),
  instant_booking_event_ids: yup.array().of(yup.number()).default([]),
  requestable_event_ids: yup.array().of(yup.number()).default([]),
  booking_ids: yup.array().of(yup.number()).default([]),
  request_ids: yup.array().of(yup.number()).default([]),
  note_id: yup.number().nullable().default(null),
  note: yup.object({}).default({}),
  overrides: scheduleOverridesSchema,
  booked_seats: yup.object({}).default({}),
  requested_seats: yup.object({}).default({}),
})

export function create(data) {
  return cast(scheduleSchema, data)
}

export const emptySchedule = create()
