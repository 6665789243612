import { useCallback } from 'react'
import { useTranslation } from 'next-i18next'

export function useMegaMenuLinks({ hideAllNavItems, hideExperiences, hidePrivateEvent, megaMenuActiveIndex }) {
  const { t } = useTranslation()

  const filter = useCallback(
    (link) => {
      if (megaMenuActiveIndex) return link.prismicKey === megaMenuActiveIndex
      return Boolean(link)
    },
    [megaMenuActiveIndex],
  )

  if (hideAllNavItems) return []

  const megaMenuLinks = [
    { title: t('Header::Gift cards'), prismicKey: 'gift_card', fallbackHref: '/landings/gifts' },
    !hideExperiences && {
      title: t('Header::Experiences'),
      prismicKey: 'in_person_experiences',
      fallbackHref: '/search',
    },
    !hidePrivateEvent && {
      title: t('Header::Private Events'),
      prismicKey: 'private_experiences',
      fallbackHref: '/landings/private-events',
    },
  ]

  return megaMenuLinks.filter(filter)
}
