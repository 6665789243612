import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SmartLink } from 'components/shared/SmartLink'

export const NavItem = styled(({ highlighted, transparent, ...props }) => <SmartLink {...props} />)`
  font-size: ${({ highlighted }) => (highlighted ? '16px' : '14px')};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  background: ${({ highlighted, theme }) => (highlighted ? theme.colors.primary : null)};
  color: ${({ highlighted, transparent, theme }) =>
    highlighted || transparent ? theme.colors.white : theme.colors.mediumGray};
  padding: ${({ highlighted }) => (highlighted ? '8px' : '0')} 0;
  border-radius: ${({ highlighted, theme }) => (highlighted ? theme.radii.full : 'none')};

  &:hover,
  &:focus,
  &:active {
    color: ${({ highlighted, transparent }) => (highlighted || transparent ? 'white' : 'mediumGray')} !important;
  }

  ${({ theme }) => theme.media.desktop`
    padding: ${({ highlighted }) => (highlighted ? '8px' : '0')} 12px;
  `}
`

export const commonHeaderPropTypes = {
  hideAllNavItems: PropTypes.bool,
  hideExperiences: PropTypes.bool,
  hidePrivateEvent: PropTypes.bool,
  hideResumeApplication: PropTypes.bool,
  isTransparent: PropTypes.bool,
}
export const commonHeaderDefaultProps = {
  hideAllNavItems: false,
  hideExperiences: false,
  hidePrivateEvent: true,
  hideResumeApplication: false,
  isTransparent: false,
}
