import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { gtmEventDaySelected } from 'gtm'
import { Text } from '@vizeat/components/es6/components/Text'
import { EventCalendarPicker } from 'components/shared/inputs'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Divider } from '@vizeat/components/es6/components/Divider'
import IconDayPickerSoldout from '@vizeat/components/es6/assets/icons/IconDayPickerSoldout'

export function SelectEventDateInput({ availability, closeDropdown, event, onChange, onClick, privatize, value }) {
  const { t } = useTranslation()
  const matches = useMediaQuery('tablet')

  // TODO: today it's too complicated to retrieve information about the selected date such as hasGuests and isIntantt to forward to GTM
  function handleDayClick(mDate) {
    closeDropdown()
    gtmEventDaySelected({
      eventId: event.id,
      availability,
      date: mDate.format('YYYY-MM-DD'),
      placement: 'calendar',
      userId: event.user_id,
    })
    onChange(mDate)
  }

  if (!event.id) return null

  // On mobile must be initalMonth must be the current data because of infinite scroll not being able to load months in the past
  return (
    <Flex
      flexDirection='column'
      position='relative'
      marginTop={{ tablet: '0', default: '-24px' }}
      overflowY={{ tablet: 'hidden', default: 'scroll' }}
      onClick={onClick}
    >
      <EventCalendarPicker
        event={event}
        onDayClick={handleDayClick}
        privatize={privatize}
        initialMonth={matches ? value : moment.utc()}
        selected={value}
      />

      {!privatize && (
        <>
          {!matches && <Divider mb='16px' width='100%' color='lightGray' />}
          <Box pb='16px' px='16px' minHeight='auto'>
            <Flex flexDirection='row' alignItems='center' my='2px'>
              <IconDayPickerSoldout mr='4px' fontSize='14px' />
              <Text as='span' size='xs'>
                {t('SelectDateWidget::Sold out')}
              </Text>
            </Flex>
          </Box>
        </>
      )}
    </Flex>
  )
}

SelectEventDateInput.propTypes = {
  availability: PropTypes.string.isRequired,
  closeDropdown: PropTypes.func.isRequired, // passed via cloning children from parent...
  event: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  privatize: PropTypes.bool,
  value: PropTypes.moment,
}

SelectEventDateInput.defaultProps = {
  onClick: () => {},
  privatize: false,
  value: undefined,
}
