import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useSocialMedia } from 'hooks/socials/useSocialMedia'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Image } from '@vizeat/components/es6/components/Image'
import { Text } from '@vizeat/components/es6/components/Text'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { SmartFooterLink } from './SmartFooterLink'
import { useIsRtl } from 'hooks/locales/useIsRtl'

export function SocialLinks({ hideExternalLinks }) {
  const { t } = useTranslation()
  const { isRTL } = useIsRtl()
  const isMobile = !useMediaQuery('tablet')
  const { iconLinks } = useSocialMedia()

  return (
    <Box textAlign='center'>
      {!hideExternalLinks && (
        <>
          {isMobile && (
            <Flex justifyContent='center' gap='4px'>
              <Text as='span' color='white'>
                {t('Footer::Follow us')}
              </Text>
              <Image
                width='36px'
                src='/assets/images/arrow-spiral.png'
                alt=''
                css={`
                  transform: scaleX(${isRTL ? '-1' : '1'}) scaleY(-1) rotate(-15deg);
                  margin: 12px 0;
                `}
              />
            </Flex>
          )}

          <Grid templateColumns='repeat(6, 1fr)' justify-content='space-between' width='300px' margin='auto'>
            {iconLinks.map(({ to, Icon }) => (
              <SmartFooterLink key={to} href={to} target='_blank' rel='noopener noreferrer'>
                <Icon color='white' fontSize='24px' />
              </SmartFooterLink>
            ))}
          </Grid>
        </>
      )}
    </Box>
  )
}

SocialLinks.propTypes = {
  hideExternalLinks: PropTypes.bool,
}

SocialLinks.defaultProps = {
  hideExternalLinks: false,
}
