import React from 'react'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Text } from '@vizeat/components/es6/components/Text'
import { IconMail } from '@vizeat/components/es6/assets/icons'
import { MenuItem } from './styled'

export function Availability() {
  const { t } = useTranslation()
  const { appCurrency } = useCurrency()

  return (
    <>
      <Text as='span' my='4px' size='s' color='mediumGray'>
        {appCurrency.iso_3 === 'ILS'
          ? t('Footer::Israel::Mon-Fri 11AM-5PM {{timezone}}', { timezone: moment().isDST() ? 'IDT' : 'IST' })
          : t('Footer::Mon-Fri 10AM-4PM {{timezone}}', { timezone: moment().isDST() ? 'CEST' : 'CET' })}
      </Text>

      <Flex flexDirection='row' color='mediumGray' alignItems='center' my='4px' gap='4px'>
        <IconMail size='24px' />
        <MenuItem to='mailto:support@eatwith.com'>support@eatwith.com</MenuItem>
      </Flex>
    </>
  )
}
