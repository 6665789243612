import React from 'react'
import PropTypes from 'helpers/proptypes' // components
import { SmallSpinner } from '@vizeat/components/es6/components/SmallSpinner'
import { NearbyExperiences } from './NearbyExperiences'
import { SearchResults } from './SearchResults'
import { RecentSearches } from './RecentSearches'
import { useTranslation } from 'next-i18next'

export function DropdownContent({
  onRecentSearchClick,
  allResults,
  eventResults,
  highlightedIndex,
  placeResults,
  userResults,
  getItemProps,
  isFetching,
  inputValue,
  recentSearches,
  showRecentSearches,
  showNearbyExperiences,
}) {
  const { t } = useTranslation()

  // Shows recent searches and/or nearby events when user has no current search
  if (!inputValue) {
    return (
      <>
        {showRecentSearches && (
          <RecentSearches
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
          />
        )}
        {showNearbyExperiences && <NearbyExperiences />}
      </>
    )
  }

  // If we here, it means there is already some input value
  if (allResults.length <= 0) {
    return (
      <div style={{ textAlign: 'center' }}>
        {isFetching ? <SmallSpinner /> : t('OpenSearchInput::NoResultsMessage::No results')}
      </div>
    )
  }

  return (
    <SearchResults
      eventResults={eventResults}
      highlightedIndex={highlightedIndex}
      placeResults={placeResults}
      userResults={userResults}
      getItemProps={getItemProps}
      inputValue={inputValue}
    />
  )
}

DropdownContent.propTypes = {
  onRecentSearchClick: PropTypes.func,
  allResults: PropTypes.arrayOf(PropTypes.shape({})),
  eventResults: PropTypes.arrayOf(PropTypes.shape({})),
  highlightedIndex: PropTypes.number.isRequired,
  placeResults: PropTypes.arrayOf(PropTypes.shape({})),
  userResults: PropTypes.arrayOf(PropTypes.shape({})),
  getItemProps: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  inputValue: PropTypes.string,
  recentSearches: PropTypes.arrayOf(PropTypes.shape({})),
  showRecentSearches: PropTypes.bool.isRequired,
  showNearbyExperiences: PropTypes.bool.isRequired,
}

DropdownContent.defaultProps = {
  onRecentSearchClick: undefined,
  allResults: [],
  eventResults: [],
  placeResults: [],
  userResults: [],
  inputValue: undefined,
  recentSearches: [],
}
