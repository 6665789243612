import { cast } from 'helpers/schema'
import * as yup from 'yup'

const tagSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
  original_title: yup.string().required(),
  description: yup.string().nullable(),
  metadata: yup.mixed().nullable(),
})

const categorySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  original_name: yup.string().required(),
  description: yup.string().nullable().default(null),
  color: yup
    .string()
    .matches(/^#[0-9a-fA-F]{6}$/)
    .nullable()
    .default(null),
  tags: yup.array().of(tagSchema).required(),
})

export const categoriesSchema = yup.array().of(categorySchema).default([])

export function create(data) {
  return cast(categoriesSchema, data)
}

export const emptyCategories = create()
