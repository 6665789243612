import * as yup from 'yup'
import { currencySchema } from './currency'
import { fileSchema } from './file'
import { languageSchema } from './language'
import { accountSchema } from './account'
import { partnerSchema } from './partner'
import { cast } from 'helpers/schema'

export const userSchema = yup.object({
  id: yup.number().optional(),
  account: accountSchema.required(),
  account_id: yup.number().required(),
  age: yup.string().nullable().default(null),
  avatar: fileSchema.nullable().default(null),
  avatar_id: yup.string().nullable().default(null),
  birth_date: yup.string().nullable().default(null),
  civility: yup.string().oneOf(['MR', 'MS', 'OTHER']).nullable(),
  country: yup.string(),
  country_iso: yup.string().nullable().default(null),
  currency: currencySchema,
  currency_id: yup.number().required(),
  description: yup.string().nullable().default(null),
  events: yup.array().of(yup.object({})).default([]),
  files: yup.array().of(fileSchema).default([]),
  firstname: yup.string().default(''),
  languages: yup.array().of(languageSchema).default([]),
  lastname: yup.string().default(''),
  partner: partnerSchema.nullable().default(null),
  phone: yup.string().nullable().default(null),
  locality: yup.string().nullable().default(null),
  properties: yup.object({}).default({}),
  rating: yup
    .object({
      count: yup.number(),
      score: yup.number().nullable(),
    })
    .default({}),
  tags: yup.array().of(yup.object({})).default([]),
  published_review_count: yup.number().default(0),
  created_at: yup.string().nullable().default(null),
  updated_at: yup.string().nullable().default(null),
})

export function create(data) {
  return cast(userSchema, data)
}

export const emptyUser = create()
