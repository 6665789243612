import { useEffect } from 'react'
import { useEventPricingQuery } from 'hooks/queries/event'
import { konstants, numbers } from '@vizeat/helpers'
import settings from 'settings'

export function useMetaTracking({ event, date, seats, bookingId }) {
  const shouldTrack = typeof window?.fbq !== 'undefined' && settings.iMetaPixelActive
  const { data: pricing, error } = useEventPricingQuery(
    event.id,
    {
      seats,
      date,
      currencyIso3: konstants.DEFAULT_CURRENCY.iso_3,
    },
    { enabled: shouldTrack },
  )

  useEffect(() => {
    if (shouldTrack && bookingId && !error) {
      window.fbq('track', 'Purchase', {
        currency: pricing.payer.currency.iso_3?.toLowerCase(),
        value: Number(
          numbers
            .formatPriceAsFloat(pricing.payer.total_paid, pricing.payer.currency)
            .toFixed(pricing.payer.currency.decimal_point),
        ),
      })
    }
  }, [bookingId, error, pricing.payer.currency, pricing.payer.total_paid, shouldTrack])
}
