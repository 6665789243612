import React from 'react'
import PropTypes from 'helpers/proptypes'
import { Trans } from 'next-i18next'

export function Plural({ singularKey, pluralKey, renderCount, variables, components }) {
  if (renderCount && renderCount === 0) return null

  return (
    <Trans
      i18nKey={renderCount === 1 ? singularKey : pluralKey}
      count={renderCount}
      values={{ count: parseInt(renderCount, 10), ...variables }}
      components={components}
    />
  )
}

Plural.propTypes = {
  singularKey: PropTypes.string,
  pluralKey: PropTypes.string,
  renderCount: PropTypes.number,
  variables: PropTypes.shape({}),
  components: PropTypes.shape({}),
}
Plural.defaultProps = {
  singularKey: undefined,
  pluralKey: undefined,
  renderCount: undefined,
  variables: {},
  components: undefined,
}
