import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useIsRtl } from 'hooks/locales/useIsRtl'

export const directionIconPropTypes = {
  direction: PropTypes.oneOf(['forward', 'backward']),
}

export const directionIconDefaultProps = {
  direction: 'forward',
}

export function DirectionIcon({ direction, IconLeft, IconRight, ...props }) {
  const { isRTL } = useIsRtl()

  if (direction === 'forward') return isRTL ? <IconLeft {...props} /> : <IconRight {...props} />
  return isRTL ? <IconRight {...props} /> : <IconLeft {...props} />
}

DirectionIcon.propTypes = {
  ...directionIconPropTypes,
  IconLeft: PropTypes.shape({}).isRequired,
  IconRight: PropTypes.shape({}).isRequired,
}

DirectionIcon.defaultProps = {
  ...directionIconDefaultProps,
}
