import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { CalendarPicker } from 'components/shared/inputs'
import { isBookable, isOpened, isSoldOut, useEventMonthSchedulesFromDateQuery } from 'hooks/queries/schedule'

const emptyModifiers = {
  bookableSchedules: [],
  nonBookableSchedules: [],
  openedSchedules: [],
  nonOpenedSchedules: [],
  soldOutSchedules: [],
  isScheduleSoldOut: () => false,
}

function useDefaultModifiers(event, activeMonth) {
  const { id: eventId } = event

  const { data: modifiers = emptyModifiers } = useEventMonthSchedulesFromDateQuery(event.id, activeMonth, {
    select: useCallback(
      ({ schedule }) => {
        return {
          bookableSchedules: schedule.filter((s) => isBookable(s, eventId)),
          nonBookableSchedules: schedule.filter((s) => !isBookable(s, eventId)),
          openedSchedules: schedule.filter((s) => isOpened(s, eventId)),
          nonOpenedSchedules: schedule.filter((s) => !isOpened(s, eventId)),
          soldOutSchedules: schedule.filter((s) => isSoldOut(s, event)),
          isScheduleSoldOut: (mDate) => {
            const date = mDate.format('YYYY-MM-DD')
            const selectedSchedule = schedule.find((s) => s.date === date)
            return isSoldOut(selectedSchedule, event)
          },
        }
      },
      [event, eventId],
    ),
  })

  return modifiers
}

export function EventCalendarPicker({
  event,
  onDayClick,
  onDayHover,
  onMonthChange,
  privatize,
  initialMonth,
  selected,
  selectedDays,
  modifiers,
}) {
  const [hovered, setHovered] = useState(undefined)
  const [activeMonth, setActiveMonth] = useState(selected || initialMonth)
  useEffect(() => {
    setActiveMonth(selected || initialMonth)
  }, [initialMonth, selected])

  const defaultModifiers = useDefaultModifiers(event, activeMonth)

  const handleDayClick = (mDate, currentModifiers) => {
    if (currentModifiers.outside || currentModifiers.closed || currentModifiers.disabled) return
    return onDayClick(mDate)
  }

  const handleDayMouseEnter = (mDate, currentModifiers) => {
    if (currentModifiers.outside || currentModifiers.disabled) return
    setHovered(mDate)
    onDayHover?.(mDate)
  }

  const handleDayMouseLeave = () => {
    setHovered(undefined)
    onDayHover?.(undefined)
  }

  const handleMonthChange = (mDate) => {
    setActiveMonth(mDate)
    onMonthChange?.(mDate)
  }

  const calendarModifiers = useMemo(() => {
    if (modifiers) return modifiers

    return {
      selected,
      hovered,
      closed: (privatize ? defaultModifiers.nonBookableSchedules : defaultModifiers.nonOpenedSchedules).map(
        ({ date }) => moment.utc(date),
      ),
      opened: privatize ? undefined : defaultModifiers.openedSchedules.map(({ date }) => moment.utc(date)),
      soldout: privatize ? undefined : defaultModifiers.soldOutSchedules.map(({ date }) => moment.utc(date)),
      disabled: (mUtcDate) => {
        return (
          mUtcDate.isBefore(moment.utc(), 'day') ||
          // A date might not be bookable if it's closed, sold-out, privatized or booking deadline is past
          // We want to show as disabled the dates that are no longer bookable because booking deadline
          // is past, but not dates that are soldout to create FOMO
          // Dates that have alternative events should not be shown as disabled
          (!defaultModifiers.bookableSchedules.find((s) => s.date === mUtcDate.format('YYYY-MM-DD')) &&
            !defaultModifiers.isScheduleSoldOut(mUtcDate))
        )
      },
    }
  }, [defaultModifiers, hovered, modifiers, privatize, selected])

  return (
    <CalendarPicker
      key={activeMonth.format('YYYY-MM-DD')} // Force rerender to ensure correct number of months and initial month when it's autocompleted...
      fromMonth={moment.utc()}
      modifiers={calendarModifiers}
      initialMonth={activeMonth}
      numberOfMonths={1}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      onDayMouseLeave={handleDayMouseLeave}
      onMonthChange={handleMonthChange}
      selectedDays={selectedDays}
    />
  )
}

EventCalendarPicker.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onDayClick: PropTypes.func.isRequired,
  onDayHover: PropTypes.func,
  initialMonth: PropTypes.moment,
  modifiers: PropTypes.shape({}),
  onMonthChange: PropTypes.func,
  privatize: PropTypes.bool,
  selected: PropTypes.moment,
  selectedDays: PropTypes.arrayOf(PropTypes.moment),
}

EventCalendarPicker.defaultProps = {
  onDayHover: undefined,
  initialMonth: moment.utc().date(15),
  onMonthChange: () => null,
  privatize: false,
  selected: undefined,
  selectedDays: undefined,
  modifiers: undefined,
}
