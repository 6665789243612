import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { Input } from '@vizeat/components/es6/components/Input'
import { useToggle } from '@vizeat/components/es6/hooks/useToggle/useToggle'
import { Box } from '@vizeat/components/es6/components/Box'
import { AnimatedChevron, Collapse } from '@vizeat/components/es6/components/Collapse/Collapse'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'

export function DropDownInput({
  children,
  value,
  placeholder,
  label,
  description,
  error,
  isRequired,
  defaultOpen,
  inputContainerStyles,
  name,
  position,
}) {
  const [isExpanded, { toggle }] = useToggle(defaultOpen)
  const isTabletOrHigher = useMediaQuery('tablet')

  const Trigger = useMemo(
    () => (
      <Box onClick={isTabletOrHigher ? undefined : toggle} width='100%'>
        <Input
          name={name}
          containerStyles={inputContainerStyles}
          description={description}
          error={error}
          isRequired={isRequired}
          label={label}
          placeholder={value ? undefined : placeholder}
          value={value}
          readOnly
          css={`
            pointer-events: none;
            &::placeholder {
              color: ${({ theme }) => theme.colors.black};
            }
          `}
        >
          <AnimatedChevron
            expanded={isExpanded}
            color='black'
            size='18px'
            css={`
              position: absolute;
              top: 0;
              bottom: 0;
              right: 8px;
              margin: auto 0;
            `}
          />
        </Input>
      </Box>
    ),
    [
      description,
      error,
      inputContainerStyles,
      isExpanded,
      isRequired,
      isTabletOrHigher,
      label,
      placeholder,
      toggle,
      value,
      name,
    ],
  )

  if (isTabletOrHigher) {
    return (
      <Dropdown
        id={name}
        animate={false}
        position={position}
        dropdownStyles='bottom: -8px; padding: 0 12px;'
        Trigger={Trigger}
      >
        {({ handleDropdownHide }) =>
          typeof children === 'function' ? children({ handleClose: handleDropdownHide }) : children
        }
      </Dropdown>
    )
  }

  return (
    <Collapse isExpanded={isExpanded} renderTrigger={() => <>{Trigger}</>}>
      <Box
        bg='white'
        borderRadius='lg'
        overflow='hidden'
        css={`
          .DayPicker .DayPicker-Month {
            margin-top: 24px;
            margin-bottom: 0;
          }

          hr {
            margin: 8px 0;
          }
        `}
      >
        {typeof children === 'function' ? children({ handleClose: toggle }) : children}
      </Box>
    </Collapse>
  )
}

DropDownInput.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  inputContainerStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  defaultOpen: PropTypes.bool,
  position: PropTypes.oneOf(['center']),
}

DropDownInput.defaultProps = {
  defaultOpen: false,
  description: undefined,
  error: undefined,
  isRequired: false,
  placeholder: undefined,
  value: undefined,
  inputContainerStyles: undefined,
  label: undefined,
  position: undefined,
}
