import moment from 'moment'

export function isValidDay(day) {
  const bookingDeadline = moment().add(6, 'hours')

  if (day.isBefore(moment(), 'day') || (moment().isSame(day, 'day') && bookingDeadline.isAfter(moment(), 'day'))) {
    return false
  }

  return true
}

export function toMomentDate(date) {
  if (!date) return null
  const mDate = moment.isMoment(date) ? date : moment.utc(date)
  return mDate.isValid() ? mDate : null
}
