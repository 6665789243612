import React from 'react'
import { useSocialMedia } from 'hooks/socials/useSocialMedia'
import { SmartLink } from 'components/shared/SmartLink'
import { Flex } from '@vizeat/components/es6/components/Flex'

export function SocialLinks() {
  const { iconLinks } = useSocialMedia()

  return (
    <Flex
      as='li'
      flexDirection='row'
      justifyContent='space-between'
      gap='20px'
      py='12px'
      margin={{ default: 'auto', phablet: '0' }}
    >
      {iconLinks.map(({ to, Icon }) => (
        <SmartLink key={to} href={to} target='_blank' rel='noopener noreferrer'>
          <Icon color='mediumGray' fontSize='20px' />
        </SmartLink>
      ))}
    </Flex>
  )
}
