import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'next-i18next'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getIsCurrentUserLoggedIn } from 'redux/selectors'
import { toggleLoginModal, toggleRegisterModal } from 'redux/actions'
import { Button } from '@vizeat/components/es6/components/Button'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Divider } from '@vizeat/components/es6/components/Divider'

const StyledButtonWrapper = styled.li`
  padding: 0 12px;
  font-size: 14px;
`

const StyledLoginButton = styled(({ ...props }) => <Button clear {...props} />)`
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  color: ${({ isTransparent, theme }) => (isTransparent ? theme.colors.white : theme.colors.mediumGray)};

  &:hover,
  &:focus,
  &:active {
    color: ${({ isTransparent, theme }) => (isTransparent ? theme.colors.white : theme.colors.mediumGray)} !important;
  }
`

export function AuthButtons({ isTransparent, signupText }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery('desktop')
  const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)

  function showLoginModal() {
    dispatch(toggleLoginModal({ show: true }))
  }

  function showRegisterModal() {
    dispatch(toggleRegisterModal(true))
  }

  if (isCurrentUserLoggedIn) return null

  if (isDesktop)
    return (
      <>
        <StyledButtonWrapper>
          <StyledLoginButton isTransparent={isTransparent} onClick={showLoginModal}>
            {t('Header::Log in')}
          </StyledLoginButton>
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <Button
            rounded
            onClick={showRegisterModal}
            css={`
              font-weight: ${({ theme }) => theme.fontWeights.bolder};
            `}
          >
            {signupText}
          </Button>
        </StyledButtonWrapper>
      </>
    )

  return (
    <>
      <Flex flexDirection='row' gap='16px'>
        <Button fullWidth onClick={showLoginModal}>
          {t('Header::Log in')}
        </Button>
        <Button secondary fullWidth onClick={showRegisterModal}>
          {signupText}
        </Button>
      </Flex>
      <Divider color='lightGray' m='8px 0' />
    </>
  )
}

AuthButtons.propTypes = {
  isTransparent: PropTypes.bool,
  signupText: PropTypes.string.isRequired,
}

AuthButtons.defaultProps = {
  isTransparent: false,
}
