import * as yup from 'yup'
import { cast } from 'helpers/schema'

const NotificationSchema = yup.object({
  id: yup.number().required(),
  body: yup.string().required(),
  created_at: yup.string().required(),
  read_at: yup.string().nullable().default(null),
  type: yup.string().required(),
  subject: yup.object({
    id: yup.number().required(),
    conversation_id: yup.number().required(),
    event_id: yup.number().optional().default(null),
    date: yup.string().optional().default(null),
  }),
})

const NotificationsSchema = yup.object({
  notifications: yup.array().of(NotificationSchema).default([]),
  metadata: yup.object({
    offset: yup.number().required(),
    size: yup.number().required(),
    total: yup.number().required(),
    unread_count: yup.number().required().default(0),
    unread_replies_count: yup.number().required().default(0),
  }),
})

export function create(data) {
  return cast(NotificationsSchema, data)
}

export const emptyNotifications = create()
