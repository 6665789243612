import * as yup from 'yup'
import { organizationSchema } from './organization'
import { cast } from 'helpers/schema'

export const partnerSchema = yup.object({
  id: yup.number().optional(),
  account_id: yup.number().required(),
  name: yup.string().nullable().default(null),
  description: yup.string().nullable().default(null),
  organization: organizationSchema,
})

export function create(data) {
  return cast(partnerSchema, data)
}

export const emptyPartner = create()
