import { urlFactory } from 'helpers/url'

export function getDetails(notification) {
  const { type, subject } = notification

  if (!subject) {
    return {
      glyph: 'info',
      glyphColor: 'blue',
      path: urlFactory.dashboard.canonical,
      externalLink: true,
    }
  }

  switch (type) {
    case 'account_password_modified':
      return {
        glyph: 'check',
        glyphColor: 'green',
      }
    case 'account_password_renewal':
      return {
        glyph: 'info',
        glyphColor: 'blue',
        path: urlFactory.dashboard.users.security(),
        externalLink: true,
      }
    case 'account_registration_confirmation':
      return {
        glyph: 'info',
        glyphColor: 'blue',
      }
    case 'booking_guest_acknowledge':
      return {
        glyph: 'info',
        glyphColor: 'blue',
      }
    case 'booking_guest_canceled_full_refund':
      return {
        glyph: 'x',
        glyphColor: 'red',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_guest_confirmed':
      return {
        glyph: 'check',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_guest_declined':
      return {
        glyph: 'exclamation',
        glyphColor: 'red',
      }
    case 'booking_guest_request_accepted':
      return {
        glyph: 'calendar',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_guest_past':
      return {
        glyph: 'x',
        glyphColor: 'red',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_guest_upcoming':
      return {
        glyph: 'calendar',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_host_acknowledge':
      return {
        glyph: 'info',
        glyphColor: 'blue',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_host_canceled_by_guest':
      return {
        glyph: 'x',
        glyphColor: 'red',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_host_confirmed':
      return {
        glyph: 'check',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_host_declined':
      return {
        glyph: 'x',
        glyphColor: 'red',
        path: urlFactory.dashboard.planning.index(),
        externalLink: true,
      }
    case 'booking_host_past':
      return {
        glyph: 'x',
        glyphColor: 'red',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_host_payout_information':
      return {
        glyph: 'info',
        glyphColor: 'blue',
        path: urlFactory.dashboard.users.payoutPreferences(),
        externalLink: true,
      }
    case 'booking_host_request_awaiting_reply':
      return {
        glyph: 'comment',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_host_request_information':
      return {
        glyph: 'calendar',
        glyphColor: 'blue',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'booking_host_upcoming':
      return {
        glyph: 'calendar',
        glyphColor: 'green',
        externalLink: true,
        path: urlFactory.dashboard.planning.eventAtDate(subject.event_id, subject.date.replace(/-/g, '/')),
      }
    case 'conversation_new_message':
      return {
        glyph: 'comment',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'event_first_accepted':
      return {
        glyph: 'check',
        glyphColor: 'green',
        path: urlFactory.dashboard.events.index(),
        externalLink: true,
      }
    case 'event_rejected':
      return {
        glyph: 'x',
        glyphColor: 'red',
        path: urlFactory.dashboard.events.edit(subject.id),
        externalLink: true,
      }
    case 'event_submitted':
      return {
        glyph: 'info',
        glyphColor: 'blue',
      }
    case 'booking_private_event_guest_accepted':
      return {
        glyph: 'calendar',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'review_guest_first_reminder':
      return {
        glyph: 'star',
        glyphColor: 'green',
        path: urlFactory.dashboard.bookings.index({ review: true }),
        externalLink: true,
      }
    case 'review_guest_second_reminder':
      return {
        glyph: 'star',
        glyphColor: 'green',
        path: urlFactory.dashboard.bookings.index({ review: true }),
        externalLink: true,
      }
    case 'review_host_first_reminder':
      return {
        glyph: 'star',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'review_host_second_reminder':
      return {
        glyph: 'star',
        glyphColor: 'green',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'review_new':
      return {
        glyph: 'star',
        glyphColor: 'green',
        path: urlFactory.dashboard.users.reviews.index(),
        externalLink: true,
      }
    case 'request_guest_private_event_accepted':
      return {
        glyph: 'check',
        glyphColor: 'blue',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'request_guest_request_a_date_accepted':
      return {
        glyph: 'check',
        glyphColor: 'blue',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'request_host_private_event_acknowledge':
      return {
        glyph: 'check',
        glyphColor: 'blue',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }
    case 'request_host_request_a_date_acknowledge':
      return {
        glyph: 'check',
        glyphColor: 'blue',
        path: urlFactory.dashboard.inbox.get(subject.conversation_id),
        externalLink: true,
      }

    // Host onboarding
    case 'host_application_submitted': {
      return {
        glyph: 'check',
        glyphColor: 'blue',
      }
    }

    case 'event_incomplete':
    case 'host_application_after_approval_before_submit':
    case 'host_application_approved':
      return {
        glyph: 'check',
        glyphColor: 'blue',
        path: urlFactory.dashboard.events.edit(subject.id),
        externalLink: true,
      }

    case 'host_application_no_schedule':
      return {
        glyph: 'check',
        glyphColor: 'blue',
        path: urlFactory.dashboard.planning.index(),
        externalLink: true,
      }

    case 'host_application_rejected_last_reminder':
    case 'host_application_rejected_first_reminder':
    case 'host_application_rejected':
    case 'host_application_not_submitted':
      return {
        glyph: 'check',
        glyphColor: 'blue',
        externalLink: true,
        path: urlFactory.dashboard.hostApplication.resume(),
      }

    default:
      return {
        glyph: 'info',
        glyphColor: 'blue',
        path: urlFactory.dashboard.canonical,
        externalLink: true,
      }
  }
}
