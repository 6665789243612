import { useQueries, useQuery } from '@tanstack/react-query'
import { getEvent, getEventFiles, getEventPlace, getEventPricing } from 'api/event'
import { STALE_TIME, serializeQueryKey } from 'helpers/query'
import { transform as transformPricing, emptyPricing } from 'schemas/pricing'
import { create as createEvent, emptyEvent } from 'schemas/event'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { create as createFile } from 'schemas/file'
import { create as createPlaceLight, emptyPlaceLight } from 'schemas/place-light'
import { useMemo } from 'react'

function getQuerykey(key, id, query, currency) {
  return serializeQueryKey(
    key,
    {
      id,
      ...query,
    },
    currency,
  )
}

export function getEventQueryKey(id, query, currency) {
  return getQuerykey('event', id, query, currency)
}

export function getEventPricingQueryKey(id, query, currency) {
  return getQuerykey('pricing', id, query, currency)
}

export function useEventQueryKey(id, query) {
  const { appCurrencyIso3 } = useCurrency()
  return getEventQueryKey(id, query, appCurrencyIso3)
}

export function getEventFilesQueryKey(id, query, currency) {
  return getQuerykey('event.files', id, query, currency)
}

export function useEventPricingQueryKey(id, query) {
  const { appCurrencyIso3 } = useCurrency()
  const currencyIso3 = query?.currencyIso3 || appCurrencyIso3
  return getEventPricingQueryKey(id, query, currencyIso3)
}

export function useEventQuery(id, query, reactQueryOptions) {
  const eventQueryKey = useEventQueryKey(id, query)

  return useQuery({
    placeholderData: emptyEvent,
    keepPreviousData: true,
    staleTime: STALE_TIME,
    queryKey: eventQueryKey,
    queryFn: () => getEvent(id, query),
    select: createEvent,
    ...reactQueryOptions,
  })
}

export function useEventPricingQuery(eventId, { promoCode, ...baseQuery } = {}, reactQueryOptions) {
  const queryWithPromoCode = { promoCode, ...baseQuery }
  const pricingQueryKey = useEventPricingQueryKey(eventId, baseQuery)
  const pricingWithPromoCodeQueryKey = useEventPricingQueryKey(eventId, queryWithPromoCode)

  const options = {
    placeholderData: emptyPricing,
    staleTime: STALE_TIME,
    select: transformPricing,
    ...reactQueryOptions,
  }

  const pricingQuery = useQuery({
    queryKey: pricingQueryKey,
    queryFn: () => getEventPricing(eventId, baseQuery),
    enabled: !promoCode,
    ...options,
  })

  const pricingWithPromoCodeQuery = useQuery({
    queryKey: pricingWithPromoCodeQueryKey,
    queryFn: () => getEventPricing(eventId, queryWithPromoCode),
    enabled: !!promoCode,
    ...options,
  })

  if (!promoCode) return pricingQuery

  // The data becomes undefined when the query with promoCode fails,
  // in this case we should switch back to the original pricing data (without promoCode)
  return pricingWithPromoCodeQuery.isError
    ? { ...pricingWithPromoCodeQuery, data: pricingQuery.data, isInvalidPromoCode: true }
    : pricingWithPromoCodeQuery
}

export function useEventPlaceQuery(id, reactQueryOptions) {
  return useQuery({
    placeholderData: emptyPlaceLight,
    keepPreviousData: true,
    staleTime: STALE_TIME,
    queryKey: ['event', id, 'place'],
    queryFn: () => getEventPlace(id),
    select: createPlaceLight,
    ...reactQueryOptions,
  })
}

function createFiles(files) {
  return files.map(createFile)
}

export function useEventFilesQuery(eventId, query, reactQueryOptions) {
  return useQuery({
    staleTime: STALE_TIME,
    placeholderData: [],
    queryKey: getQuerykey('file', eventId, query),
    queryFn: () => getEventFiles(eventId, query, reactQueryOptions),
    select: createFiles,
    ...reactQueryOptions,
  })
}

export function useSortedEventsFiles(events, reactQueryOptions) {
  const eventFileQueries = useQueries({
    queries: events.map(({ id }) => ({
      queryKey: getEventFilesQueryKey(id),
      queryFn: () => getEventFiles(id),
      staleTime: STALE_TIME,
      ...reactQueryOptions,
    })),
  })

  const isLoading = useMemo(() => eventFileQueries.some((query) => query.isLoading), [eventFileQueries])

  return useMemo(() => {
    if (isLoading) {
      return {
        isLoading: true,
        data: [],
      }
    }

    const covers = events.map(({ cover }) => cover).filter((cover) => !!cover)
    const allFiles = eventFileQueries.reduce((acc, { data, isSuccess }) => (isSuccess ? acc.concat(data) : acc), covers)
    const uniqueFilesMap = Object.fromEntries(allFiles.map((file) => [file.id, file]))
    return {
      isLoading: false,
      data: Object.values(uniqueFilesMap).sort((a, b) => a.order - b.order),
    }
  }, [eventFileQueries, events, isLoading])
}
