import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const languageSchema = yup.object({
  id: yup.number().optional(),
  title: yup.string(),
  original_title: yup.string().required(),
  translated: yup.boolean().default(false),
  activated: yup.boolean().default(false),
  i_speak: yup.string().nullable().default(null),
  alpha2: yup.string().required(),
  locale: yup.string().required(),
  'alpha3-b': yup.string().nullable().default(null),
  'alpha3-t': yup.string().nullable().default(null),
})

export function create(data) {
  return cast(languageSchema, data)
}

export const emptyLanguage = create()
