import dynamic from 'next/dynamic'
import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { SmartLink } from 'components/shared/SmartLink'
import { DesktopNav } from './DesktopNav'
import { MobileNav } from './MobileNav'
import { commonHeaderPropTypes, commonHeaderDefaultProps } from './styled'
import { toggleLoginModal, toggleRegisterModal, logout } from 'redux/actions'
import {
  getCurrentUser,
  getIsCurrentUserLoggedIn,
  getIsLoginModalVisible,
  getIsRegisterModalVisible,
} from 'redux/selectors'
import { SearchBar } from './SearchBar'
import { COOKIE_KEYS, loadCookie } from 'helpers/cookies'

function Logo({ customLogo, hideAllNavItems, isTransparent, logoSize, showSearch, showSmallLogo, SearchBarComponent }) {
  const currentUser = useSelector(getCurrentUser)
  const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)
  const isSmall = showSearch || showSmallLogo

  const src = useMemo(() => {
    const affid = loadCookie(COOKIE_KEYS.AFFID)
    const isPartner = !!currentUser.partner || (!isCurrentUserLoggedIn && affid && affid.includes('newsletter-b2b'))
    const color = isTransparent ? 'white' : 'orange'

    if (customLogo) return customLogo
    if (isPartner) return `/assets/images/logo-eatwith-partner-intl-2022-${color}.svg`
    if (isSmall) return `/assets/images/icon-eatwith-orange.svg`
    return `/assets/images/logo-eatwith-intl-2022-${color}.svg`
  }, [currentUser.partner, customLogo, isCurrentUserLoggedIn, isSmall, isTransparent])

  return (
    <Flex padding={showSearch ? '8px 0' : '16px 0'} gap='8px' alignItems='center' flexGrow={1}>
      <Box
        src={src}
        size={logoSize}
        as={hideAllNavItems ? undefined : SmartLink}
        href={hideAllNavItems ? undefined : '/'}
        $isSmall={hideAllNavItems ? false : showSearch || showSmallLogo}
        height={logoSize || '40px'}
        minWidth={logoSize || isSmall ? '40px' : '159px'}
        background={`url(${src}) no-repeat left center;`}
        backgroundSize='contain'
        display='inline-block'
        verticalAlign='middle'
        css='image-rendering: -webkit-optimize-contrast; image-rendering: crisp-edges;'
      />
      {showSearch && (SearchBarComponent || <SearchBar />)}
    </Flex>
  )
}

Logo.propTypes = {
  customLogo: PropTypes.string,
  hideAllNavItems: PropTypes.bool.isRequired,
  isTransparent: PropTypes.bool.isRequired,
  logoSize: PropTypes.string,
  showSearch: PropTypes.bool.isRequired,
  showSmallLogo: PropTypes.bool.isRequired,
  SearchBarComponent: PropTypes.node,
}

Logo.defaultProps = {
  customLogo: undefined,
  logoSize: undefined,
  SearchBarComponent: undefined,
}

const LoginModal = dynamic(() => import('components/shared/modals/LoginModal').then((mod) => mod.LoginModal), {
  ssr: false,
})
const RegisterModal = dynamic(() => import('components/shared/modals/RegisterModal').then((mod) => mod.RegisterModal), {
  ssr: false,
})

export function Header({
  isTransparent,
  hideAllNavItems,
  hideBecomeAHost,
  hideExperiences,
  hidePrivateEvent,
  hideResumeApplication,
  showSearch,
  showSmallLogo,
  customLogo,
  SearchBarComponent,
  logoSize,
}) {
  const dispatch = useDispatch()
  const { query } = useRouter()
  const isDesktopOrHigher = useMediaQuery('desktop')

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const currentUser = useSelector(getCurrentUser)
  const showLoginModal = useSelector(getIsLoginModalVisible)
  const showRegisterModal = useSelector(getIsRegisterModalVisible)

  const isHostOrApplicant = currentUser.isHost || currentUser.host_application_id
  const shouldDisplayBecomeAHostButton = !hideAllNavItems && !hideBecomeAHost && !isHostOrApplicant

  useEffect(() => {
    if (query.login) setTimeout(() => dispatch(toggleLoginModal({ show: true })), (parseInt(query.login) || 0) * 1000)
  }, [dispatch, query.login])

  useEffect(() => {
    if (query.register)
      setTimeout(() => dispatch(toggleRegisterModal({ show: true })), (parseInt(query.register) || 0) * 1000)
  }, [dispatch, query.register])

  function onLoginModalClose() {
    dispatch(toggleLoginModal({ show: false }))
    dispatch(logout())
  }

  return (
    <Box
      as='header'
      width={{ large: '1152px', desktop: '968px', default: '100%' }}
      mx='auto'
      bg={`rgba(255, 255, 255, ${isTransparent ? '0' : '1'})`}
      color={isTransparent ? 'white' : 'mediumGray'}
      position='relative'
      zIndex={isMobileMenuOpen ? '1041' : '501'}
      css='transition: background-color 0.3s ease;'
    >
      <Flex
        as={isDesktopOrHigher ? 'nav' : undefined}
        padding='0 14px'
        margin='0 auto'
        alignItems='center'
        justifyContent='space-between'
        gap='12px'
      >
        <Logo
          customLogo={customLogo}
          hideAllNavItems={hideAllNavItems}
          isTransparent={isTransparent}
          logoSize={logoSize}
          showSearch={showSearch}
          showSmallLogo={showSmallLogo}
          SearchBarComponent={SearchBarComponent}
        />
        {isDesktopOrHigher ? (
          <DesktopNav
            hideAllNavItems={hideAllNavItems}
            hideExperiences={hideExperiences}
            hidePrivateEvent={hidePrivateEvent}
            hideResumeApplication={hideResumeApplication}
            isTransparent={isTransparent}
            shouldDisplayBecomeAHostButton={shouldDisplayBecomeAHostButton}
          />
        ) : (
          <MobileNav
            hideAllNavItems={hideAllNavItems}
            hideExperiences={hideExperiences}
            hidePrivateEvent={hidePrivateEvent}
            hideResumeApplication={hideResumeApplication}
            isTransparent={isTransparent}
            onMenuToggle={setIsMobileMenuOpen}
            shouldDisplayBecomeAHostButton={shouldDisplayBecomeAHostButton}
          />
        )}
      </Flex>
      {showLoginModal && <LoginModal onClose={onLoginModalClose} />}
      {showRegisterModal && <RegisterModal onClose={() => dispatch(toggleRegisterModal(false))} />}
    </Box>
  )
}

Header.propTypes = {
  ...commonHeaderPropTypes,
  hideBecomeAHost: PropTypes.bool,
  showSearch: PropTypes.bool,
  showSmallLogo: PropTypes.bool,
  customLogo: PropTypes.string,
  SearchBarComponent: PropTypes.node,
  logoSize: PropTypes.string,
}

Header.defaultProps = {
  ...commonHeaderDefaultProps,
  hideBecomeAHost: false,
  showSearch: false,
  showSmallLogo: false,
  customLogo: undefined,
  SearchBarComponent: undefined,
  logoSize: undefined,
}
