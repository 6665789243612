import get from 'lodash/get'

import { numbers, konstants } from '@vizeat/helpers'
const { formatIntegerPrice } = numbers
const { SIGN_UP_CAMPAIGN } = konstants

export const getRegistrationDiscount = (currency, language) => {
  // return null to hide discount
  return formatIntegerPrice(get(SIGN_UP_CAMPAIGN[currency.iso_3], 'value', 1000), language, currency)
}
