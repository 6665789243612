import React from 'react'
import { useTranslation } from 'next-i18next'
import { Box } from '@vizeat/components/es6/components/Box'
import { Button } from '@vizeat/components/es6/components/Button'
import { MenuItemsList, MenuItem, linkBaseStyle } from './styled'
import { urlFactory } from 'helpers/url'

export function SupportLinks() {
  const { t } = useTranslation()

  return (
    <MenuItemsList>
      <Box as='li' fontWeight='bold' fontSize='14px' color='mediumGray'>
        <MenuItem href={urlFactory.web.howItWorks()}>{t('Header::How it works')}</MenuItem>
      </Box>
      <li>
        <Button clear className='support-trigger' css={linkBaseStyle}>
          {t('Header::FAQ')}
        </Button>
      </li>
      <li>
        <Button clear className='support-trigger' css={linkBaseStyle}>
          {t('Header::Contact us')}
        </Button>
      </li>
    </MenuItemsList>
  )
}
