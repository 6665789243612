import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getNotificationsQueryKey } from 'hooks/queries/notifications'
import { markAllNotificationsAsRead } from 'api/notifications'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useNotificationsQuery } from 'hooks/queries/notifications/useNotificationsQuery'
import { useToggle } from '@vizeat/components/es6/hooks/useToggle'
import { Box } from '@vizeat/components/es6/components/Box'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { Toggle } from './Toggle'
import { NotificationsList } from './NotificationsList.jsx'
import { MobileModal, MobileModalTitle } from '@vizeat/components/es6/components/MobileModal'
import { StyledNotificationBadge, MarkAllAsReadButton } from './styled'

function useNotificationsData(userId) {
  const queryClient = useQueryClient()

  const { selectData } = useNotificationsQuery()
  const { mutate } = useMutation({
    mutationFn: markAllNotificationsAsRead,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: getNotificationsQueryKey(userId),
      }),
  })

  return { unread: selectData(({ metadata }) => metadata.unread_count), markAllAsRead: mutate }
}

export function NotificationsDropdown({ as, icon, styles, badgeStyles }) {
  const { t } = useTranslation()
  const currentUser = useSelector(getCurrentUser)
  const { unread, markAllAsRead } = useNotificationsData(currentUser.id)
  const [isModalOpen, { toggle: toggleModal }] = useToggle()
  const isTabletOrHigher = useMediaQuery('tablet')

  if (!currentUser.id) return null

  if (isTabletOrHigher)
    return (
      <Box as={as}>
        <Dropdown
          id='notifications'
          position='left'
          Trigger={<Toggle unread={unread} />}
          dropdownStyles='& > div { padding: 0; };'
        >
          <NotificationsList markAllAsRead={markAllAsRead} />
        </Dropdown>
      </Box>
    )

  return (
    <Box as={as}>
      <Toggle unread={unread} onClick={toggleModal} icon={icon} styles={styles} badgeStyles={badgeStyles} />
      <MobileModal
        show={isModalOpen}
        onHide={toggleModal}
        variant='bottomToTop'
        Header={
          <>
            <MobileModalTitle
              styles='padding-right: 0; align-items: center;'
              content={t('Notifications::Notifications')}
            />
            {unread > 0 && (
              <>
                <StyledNotificationBadge>{unread}</StyledNotificationBadge>
                <MarkAllAsReadButton onClick={markAllAsRead}>
                  {t('Notifications::Mark all as read')}
                </MarkAllAsReadButton>
              </>
            )}
          </>
        }
      >
        <NotificationsList markAllAsRead={markAllAsRead} closeModal={toggleModal} />
      </MobileModal>
    </Box>
  )
}

NotificationsDropdown.propTypes = {
  as: PropTypes.string,
  styles: PropTypes.string,
  badgeStyles: PropTypes.string,
  icon: PropTypes.node,
}

NotificationsDropdown.defaultProps = {
  as: undefined,
  styles: undefined,
  badgeStyles: undefined,
  icon: null,
}
