import React from 'react'
import IconArrowLeft from '@vizeat/components/es6/assets/icons/IconArrowLeft'
import IconArrowRight from '@vizeat/components/es6/assets/icons/IconArrowRight'
import { DirectionIcon, directionIconDefaultProps, directionIconPropTypes } from './DirectionIcon'

export function HorizontalDirectionArrowIcon({ direction, ...props }) {
  return <DirectionIcon direction={direction} IconLeft={IconArrowLeft} IconRight={IconArrowRight} {...props} />
}

HorizontalDirectionArrowIcon.propTypes = {
  ...directionIconPropTypes,
}

HorizontalDirectionArrowIcon.defaultProps = {
  ...directionIconDefaultProps,
}
