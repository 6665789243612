import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getNotificationsQueryKey } from 'hooks/queries/notifications'
import { setNotificationAsRead } from 'api/notifications'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { RoundedIconWrapper } from 'components/shared/RoundedIconWrapper'
import {
  IconInfo,
  IconCheck,
  IconCross,
  IconExclamation,
  IconCalendar,
  IconChatBubble,
  IconStarFilled,
} from '@vizeat/components/es6/assets/icons'
import { Text } from '@vizeat/components/es6/components/Text'
import { getDetails } from 'helpers/notifications'

const ICON_IMAGE_SOURCE = {
  info: IconInfo,
  check: IconCheck,
  x: IconCross,
  exclamation: IconExclamation,
  calendar: IconCalendar,
  comment: IconChatBubble,
  star: IconStarFilled,
}

export function NotificationItem({ isRead, closeModal, notification }) {
  const router = useRouter()
  const currentUser = useSelector(getCurrentUser)

  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: setNotificationAsRead,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: getNotificationsQueryKey(currentUser.id),
        refetchPage: (page, index, pages) => {
          const isActivePage = index === pages.length - 1 // force metadata update
          const notificationBelongToPage = page.notifications.some(({ id }) => id === notification.id) // force read status update
          return notificationBelongToPage || isActivePage
        },
      }),
  })

  const { glyphColor, path, Icon } = useMemo(() => {
    const details = getDetails(notification)
    return { ...details, Icon: ICON_IMAGE_SOURCE[details.glyph] }
  }, [notification])
  const createdAt = useMemo(() => moment.utc(notification.created_at).fromNow(), [notification.created_at])

  function handleClick() {
    if (!isRead) mutate(notification.id)
    closeModal?.()
    if (path) router.push(path)
  }

  return (
    <Flex
      as='button'
      flexDirection='row'
      alignItems='center'
      textAlign='left'
      bg={isRead ? 'lighterGray' : 'white'}
      border='none'
      p='12px'
      width='100%'
      gap='12px'
      onClick={handleClick}
    >
      <RoundedIconWrapper bg={glyphColor} width='24px' minWidth='24px' height='24px'>
        <Icon color='white' size='12px' />
      </RoundedIconWrapper>
      <Flex flexDirection='column' width='100%' color={isRead ? 'mediumGray' : 'darkGray'}>
        <Text as='span' size='xs' color='inherit'>
          {createdAt}
        </Text>
        <Text as='span' size='s' color='inherit' fontWeight={isRead ? 'normal' : 'bolder'}>
          {notification.body}
        </Text>
      </Flex>
    </Flex>
  )
}

NotificationItem.propTypes = {
  closeModal: PropTypes.func,
  isRead: PropTypes.bool.isRequired,
  notification: PropTypes.shape({
    id: PropTypes.number,
    read_at: PropTypes.string,
    created_at: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
}

NotificationItem.defaultProps = {
  closeModal: undefined,
}
