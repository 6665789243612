import React from 'react'
import PropTypes from 'helpers/proptypes' // components
import dynamic from 'next/dynamic'
import { IconHeart, IconHeartFilled } from '@vizeat/components/es6/assets/icons'
import { useAddEventToWishlist } from 'hooks/wishlists'
import { RoundedIconWrapper } from '../RoundedIconWrapper'

const WishlistsModal = dynamic(() => import('components/shared/modals').then((mod) => mod.WishlistsModal), {
  ssr: false,
})

export function WishlistButton({ event }) {
  const { handleOpenModal, handleCloseModal, isEventInWishlist, isModalOpen } = useAddEventToWishlist(event.id)

  return (
    <>
      <RoundedIconWrapper forwardedAs='button' onClick={handleOpenModal}>
        {isEventInWishlist ? <IconHeartFilled /> : <IconHeart />}
      </RoundedIconWrapper>

      <WishlistsModal show={isModalOpen} onHide={handleCloseModal} event={event} />
    </>
  )
}

WishlistButton.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
}
