import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { updateCurrentUser } from 'redux/actions'
import { useLocale } from 'hooks/locales/useLocale'
import { useLanguage } from 'hooks/languages/useLanguage'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useTheme } from 'styled-components'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Button } from '@vizeat/components/es6/components/Button'
import { ButtonIconWrapper } from '@vizeat/components/es6/components/Icon'
import IconCross from '@vizeat/components/es6/assets/icons/IconCross'
import { HorizontalDirectionChevronIcon } from 'components/shared/icons'
import { Select } from '@vizeat/components/es6/components/Select'

export function Header({ handleMegaMenuClosure, isMegaMenuOpen, showCloseIcon, toggleMenuVisibility }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { getExtendedLocale } = useLocale()
  const { appLanguage, languages, setAppLanguage } = useLanguage()
  const { appCurrency, currencies, setAppCurrency } = useCurrency()
  const theme = useTheme()
  const currentUser = useSelector(getCurrentUser)

  const languagesOptions = useMemo(
    () =>
      languages.map(({ locale }) => ({
        value: locale,
        label: getExtendedLocale(locale),
      })),
    [getExtendedLocale, languages],
  )
  const currenciesOptions = useMemo(
    () =>
      currencies.map(({ iso_3: iso3, symbol }) => ({
        value: iso3,
        label: `${iso3} ${symbol}`,
      })),
    [currencies],
  )
  const baseSelectProps = {
    containerStyles: 'margin: 0;',
    styles: {
      valueContainer: (baseStyles) => ({ ...baseStyles, fontSize: 14, fontWeight: 'normal' }),
      option: (baseStyles) => ({ ...baseStyles, color: theme.colors.darkGray }),
      control: (baseStyles) => ({ ...baseStyles, height: '36px !important' }),
    },
  }

  async function handleLocaleChange({ value: locale }) {
    if (currentUser.id) await dispatch(updateCurrentUser())
    setAppLanguage(locale)
  }

  async function handleCurrencyChange({ value: currencyIso3 }) {
    setAppCurrency(currencyIso3)
    if (currentUser.id) await dispatch(updateCurrentUser({ currency_iso3: currencyIso3 }))
  }

  if (isMegaMenuOpen)
    return (
      <Box color='lightGray'>
        <Button clear onClick={handleMegaMenuClosure}>
          <HorizontalDirectionChevronIcon direction='backward' mr='8px' size='24px' />
          {t('SidebarMenu::Back')}
        </Button>
      </Box>
    )

  return (
    <Flex
      position='relative'
      flexDirection='row'
      width='100%'
      justifyContent='space-between'
      px={{ default: '16px', tablet: '0' }}
    >
      <Flex flexDirection='row' width='70%' gap='8px'>
        <Select
          onChange={handleLocaleChange}
          options={languagesOptions}
          value={appLanguage.locale}
          isSearchable={false}
          {...baseSelectProps}
        />
        <Select
          onChange={handleCurrencyChange}
          options={currenciesOptions}
          value={appCurrency.iso_3}
          isSearchable={false}
          {...baseSelectProps}
        />
      </Flex>
      {showCloseIcon && (
        <ButtonIconWrapper onClick={toggleMenuVisibility} size='36px'>
          <IconCross size='36px' color='darkGray' />
        </ButtonIconWrapper>
      )}
    </Flex>
  )
}

Header.propTypes = {
  handleMegaMenuClosure: PropTypes.func.isRequired,
  isMegaMenuOpen: PropTypes.bool.isRequired,
  showCloseIcon: PropTypes.bool,
  toggleMenuVisibility: PropTypes.func.isRequired,
}

Header.defaultProps = {
  showCloseIcon: true,
}
