import * as yup from 'yup'
import { eventSchema } from './event'
import { placeSchema } from './place'
import { userSchema } from './user'
import { cast } from 'helpers/schema'

export const bookingSchema = yup.object({
  id: yup.number().optional(),
  additional_info: yup
    .object({
      locality: yup.string().nullable().default(null),
      country_iso: yup.string().nullable().default(null),
      datefrom: yup.date().nullable().default(null),
      dateto: yup.date().nullable().default(null),
      occasion: yup.string().nullable().default(null),
    })
    .nullable()
    .default({}),
  begins_at: yup.string(),
  conversation: yup.object({}).default({}),
  conversation_id: yup.number().required(),
  date: yup.string().required(),
  ends_at: yup.string(),
  event: eventSchema,
  event_id: yup.number().required(),
  groupStatus: yup.string().required(),
  guest_already_left_review: yup.boolean().default(false),
  host_already_left_review: yup.boolean().default(false),
  made_by_partner: yup.boolean().default(false),
  invite_token: yup.string().nullable().default(null),
  occasion: yup.string().nullable().default(null),
  payment: yup.object({}).default({}),
  place: placeSchema,
  place_id: yup.number().required(),
  publicStatus: yup.string().required(),
  reference_code: yup.string().nullable().default(null),
  request_id: yup.number().nullable().default(null),
  seats: yup.number().required(),
  host_free_seats: yup.number().required(),
  status: yup.string().required(),
  user: userSchema.required(),
  user_id: yup.number().required(),

  created_at: yup.string(),
  updated_at: yup.string(),
})

export function create(data) {
  return cast(bookingSchema, data)
}

export const emptyBooking = create()
