import { Flex } from '@vizeat/components/es6/components/Flex'
import React from 'react'
import styled from 'styled-components'

export const RoundedIconWrapper = styled((props) => (
  <Flex
    bg='white'
    borderRadius='full'
    width='36px'
    height='36px'
    alignItems='center'
    justifyContent='center'
    border='none'
    color='primary'
    {...props}
  />
))`
  &:hover:not([disabled]) {
    color: ${({ theme }) => theme.colors.red};
  }

  transition: color 0.5s ease;
`
