import React, { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'next-i18next'
import { useDashboardLinks, useMegaMenuLinks, useLandingPagesLinks } from 'hooks/header'
import { useLocale } from 'hooks/locales/useLocale'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useSelector } from 'react-redux'
import { getIsCurrentUserLoggedIn, getCurrentUser } from 'redux/selectors'
import { SmartLink } from 'components/shared/SmartLink'
import { Avatar } from 'components/shared/images'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { MegaMenu } from '../MegaMenu'
import { AuthButtons } from '../AuthButtons'
import { urlFactory } from 'helpers/url'
import { getRegistrationDiscount } from 'helpers/register'
import { konstants } from '@vizeat/helpers'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { BodyWrapper, MenuItemsList, MenuItem } from './styled'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { SupportLinks } from './SupportLinks'
import { Availability } from './Availability'
import { SocialLinks } from './SocialLinks'

export function Profile() {
  const { t } = useTranslation()
  const currentUser = useSelector(getCurrentUser)

  if (!currentUser.id) return null

  return (
    <Flex
      as={SmartLink}
      flexDirection='row'
      gap='16px'
      minHeight='auto'
      mb='32px'
      to={urlFactory.dashboard.users.profile()}
    >
      <Avatar avatar={currentUser.avatar} size={68} />
      <Flex flexDirection='column' color='mediumGray' mt='4px'>
        <Heading as='h2' color='mediumGray'>
          {t('SidebarMenu::Welcome')}
        </Heading>
        <Heading as='h2' color='mediumGray'>
          {currentUser.firstname} {currentUser.lastname}
        </Heading>
      </Flex>
    </Flex>
  )
}

export const Body = forwardRef(
  (
    {
      hideAllNavItems,
      hideExperiences,
      hidePrivateEvent,
      hideResumeApplication,
      megaMenuActiveIndex,
      setMegaMenuActiveIndex,
      shouldDisplayBecomeAHostButton,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const { locale } = useLocale()
    const dashboardLinks = useDashboardLinks({ hideAllNavItems, hideResumeApplication })
    const megamenuLinks = useMegaMenuLinks({ hideAllNavItems, hideExperiences, hidePrivateEvent, megaMenuActiveIndex })
    const { masterchef, becomeHost } = useLandingPagesLinks({ hideAllNavItems, shouldDisplayBecomeAHostButton })
    const landingPagesLinks = useMemo(
      () => Object.values([masterchef, becomeHost]).filter(Boolean),
      [becomeHost, masterchef],
    )

    const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)
    const { appCurrency } = useCurrency()
    const price = getRegistrationDiscount(appCurrency, locale)

    if (megaMenuActiveIndex)
      return (
        <BodyWrapper ref={ref} shouldDiplayLogoutButton={false}>
          <MegaMenu {...megamenuLinks[0]} activeIndex={megaMenuActiveIndex} />
        </BodyWrapper>
      )

    return (
      <BodyWrapper ref={ref} shouldDiplayLogoutButton={isCurrentUserLoggedIn}>
        <Profile />

        <Flex flexDirection='column' gap='24px'>
          {dashboardLinks.length > 0 && (
            <MenuItemsList>
              {dashboardLinks.map(({ href, text }) => (
                <Box as='li' fontSize='14px' key={href}>
                  <MenuItem href={href}>{text}</MenuItem>
                </Box>
              ))}
            </MenuItemsList>
          )}

          <AuthButtons
            signupText={
              konstants.SIGN_UP_CAMPAIGN.isActive && price
                ? t('Header::Sign up and get {{price}} off', { price })
                : t('Header::Sign up to Eatwith')
            }
          />

          {isCurrentUserLoggedIn && <Divider color='lightGray' m='8px 0' />}

          {megamenuLinks.length > 0 && (
            <MenuItemsList>
              {megamenuLinks.map(({ title, prismicKey, fallbackHref }) => (
                <MegaMenu
                  as='li'
                  key={prismicKey}
                  prismicKey={prismicKey}
                  title={title}
                  fallbackHref={fallbackHref}
                  activeIndex={megaMenuActiveIndex}
                  onMobileMegaMenuOpen={setMegaMenuActiveIndex}
                />
              ))}
            </MenuItemsList>
          )}

          {landingPagesLinks.length > 0 && (
            <>
              <Divider color='lightGray' m='8px 0' />
              <MenuItemsList>
                {landingPagesLinks.map(({ href, text }) => (
                  <Box as='li' key={href} fontSize='14px'>
                    <MenuItem href={href}>{text}</MenuItem>
                  </Box>
                ))}
              </MenuItemsList>
            </>
          )}

          <Divider color='lightGray' m='8px 0' />
          <SupportLinks />

          <Divider color='lightGray' m='8px 0' />
          <Availability />

          <Divider color='lightGray' m='8px 0' />
          <SocialLinks />
        </Flex>
      </BodyWrapper>
    )
  },
)

Body.displayName = 'SidebarMenuBody'

Body.propTypes = {
  hideAllNavItems: PropTypes.bool.isRequired,
  hideExperiences: PropTypes.bool.isRequired,
  hidePrivateEvent: PropTypes.bool.isRequired,
  hideResumeApplication: PropTypes.bool.isRequired,
  megaMenuActiveIndex: PropTypes.string,
  setMegaMenuActiveIndex: PropTypes.func.isRequired,
  shouldDisplayBecomeAHostButton: PropTypes.bool.isRequired,
}

Body.defaultProps = {
  megaMenuActiveIndex: undefined,
}
