import { useQuery } from '@tanstack/react-query'
import { getWishlists, getWishlist } from 'api/wishlists'
import { STALE_TIME } from 'helpers/query'
import { emptyWishlists, emptyWishlist } from 'schemas/wishlist'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'

export const getWishlistsQueryKey = (userId) => ['wishlists', 'userId', userId]
export const getWishlistQueryKey = (id) => ['wishlist', id]

export function useWishlistsQuery(reactQueryOptions) {
  const currentUser = useSelector(getCurrentUser)

  return useQuery({
    staleTime: STALE_TIME,
    placeholderData: emptyWishlists,
    queryKey: getWishlistsQueryKey(currentUser.id),
    queryFn: getWishlists,
    enabled: !!currentUser.id,
    ...reactQueryOptions,
  })
}

export function useWishlistQuery(id, reactQueryOptions) {
  return useQuery({
    staleTime: STALE_TIME,
    placeholderData: emptyWishlist,
    queryKey: getWishlistQueryKey(id),
    queryFn: () => getWishlist(id),
    ...reactQueryOptions,
  })
}
