import React, { useState } from 'react'
import { css } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
// Components
import { OpenSearchInput } from 'components/shared/inputs'
// helpers
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'

export function SearchBar() {
  const { t } = useTranslation()
  const router = useRouter()
  const isLoggedIn = !!useSelector(getCurrentUser).id

  const [query, setQuery] = useState('')

  function handlePlaceSelect(place) {
    router.push({
      pathname: '/search',
      query: {
        ...router.query,
        q: place.title,
        pid: place.id,
      },
    })
  }

  return (
    <OpenSearchInput
      onChange={setQuery}
      onPlaceSelect={handlePlaceSelect}
      placeholder={t('SearchPage::Destination::SearchBarPlaceholder::Search for cities, hosts or experiences')}
      value={query}
      px='12px'
      zIndex={3}
      bg='white'
      menuDropdownMaxWidth='calc(100vw - 80px)'
      css={`
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 24px;
          top: 8px;
          width: 24px;
          height: 24px;
          background: url('/assets/images/icn_search--gray.svg') no-repeat 0 0;
          background-size: 100%;
          transform: rotate(-90deg);
        }
      `}
      triggerInputCss={css`
        border-radius: ${({ theme }) => theme.radii.full};
        border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
        padding: 8px 40px 8px 16px;
        text-overflow: ellipsis;

        width: calc(100vw - ${!isLoggedIn ? '140px' : '220px'});
        ${({ theme }) =>
          !isLoggedIn &&
          theme.media.tablet`
          width: calc(100vw - 220px);
        `}

        height: 40px;
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        ${({ theme }) => theme.media.desktop`
          max-width: 200px;
        `}
        max-width: 320px;
      `}
    />
  )
}
