import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const currencySchema = yup.object({
  id: yup.number().optional(),
  iso_3: yup.string().required(),
  symbol: yup.string().required(),
  title: yup.string(),
  decimal_point: yup.number().default(2),
})

export function create(data) {
  return cast(currencySchema, data)
}

export const emptyCurrency = create()
