import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { css } from 'styled-components'
import { useDashboardLinks } from 'hooks/header'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { logout } from 'redux/actions'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { Text } from '@vizeat/components/es6/components/Text'
import { SmartLink } from 'components/shared/SmartLink'
import { Button } from '@vizeat/components/es6/components/Button'
import IconChevronDown from '@vizeat/components/es6/assets/icons/IconChevronDown'

const menuItemStyle = css`
  display: block;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
  padding: 12px;
  text-align: start;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  border-radius: ${({ theme }) => theme.radii.lg};

  &:hover,
  &:focus {
    background: ${({ theme }) => (theme.media.desktop ? `${theme.colors.lighterGray}` : 'none')};
    color: ${({ theme }) => theme.colors.darkGray};
  }
`

export function UserDropdown({ hideAllNavItems, hideResumeApplication, isTransparent }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dashboardLinks = useDashboardLinks({ hideAllNavItems, hideResumeApplication })
  const user = useSelector(getCurrentUser)

  if (!user.id) return null

  const Trigger = (
    <Flex
      flexDirection='row'
      alignItems='center'
      minWidth='60px'
      fontSize='14px'
      px='12px'
      color={isTransparent ? 'white' : 'mediumGray'}
      css='cursor: pointer;'
    >
      {user.firstname}
      {user.partner && (
        <Text as='span' italic size='xs' color={isTransparent ? 'white' : 'mediumGray'}>
          {`(${t('Header::Travel Agent')})`}
        </Text>
      )}
      <IconChevronDown size='10px' ml='4px' />
    </Flex>
  )

  return (
    <li>
      <Dropdown id='user-dropdown' position='left' Trigger={Trigger}>
        <Box as='ul' minWidth='280px' p='0' m='0' css='list-style: none;'>
          {dashboardLinks.map(({ href, text }) => (
            <Box as='li' key={href}>
              <SmartLink href={href} css={menuItemStyle}>
                {text}
              </SmartLink>
            </Box>
          ))}
          <li>
            <Button clear onClick={() => dispatch(logout())} css={menuItemStyle}>
              {t('Header::Logout')}
            </Button>
          </li>
        </Box>
      </Dropdown>
    </li>
  )
}

UserDropdown.propTypes = {
  hideAllNavItems: PropTypes.bool,
  hideResumeApplication: PropTypes.bool,
  isTransparent: PropTypes.bool.isRequired,
}

UserDropdown.defaultProps = {
  hideAllNavItems: false,
  hideResumeApplication: false,
}
