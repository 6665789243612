import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getIsCurrentUserLoggedIn } from 'redux/selectors'
import { useNotificationsQuery } from 'hooks/queries/notifications'
import { Box } from '@vizeat/components/es6/components/Box'
import { NotificationBadge } from '@vizeat/components/es6/components/NotificationBadge'
import { SmartLink } from 'components/shared/SmartLink'
import { IconMail } from '@vizeat/components/es6/assets/icons'
import { urlFactory } from 'helpers/url'

const StyledSmartLink = styled(SmartLink)`
  position: relative;
  color: ${({ theme, $transparent }) => theme.colors[$transparent ? 'white' : 'mediumGray']};
  display: flex;
  align-items: center;
  padding: 0 8px;
  ${({ theme }) => theme.media.tablet`
    padding: 0 16px;
  `}

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme, $transparent }) => theme.colors[$transparent ? 'white' : 'mediumGray']};
  }
`

const StyledNotificationBadge = styled((props) => <NotificationBadge floating {...props} />)`
  top: -15px;
`

export function InboxMenuItem({ as, transparent, icon, styles, badgeStyles }) {
  const { selectData } = useNotificationsQuery()
  const unreadMessages = selectData(({ metadata: { unread_replies_count: unreadMessages } }) => unreadMessages)
  const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)

  if (!isCurrentUserLoggedIn) return null

  return (
    <Box as={as}>
      <StyledSmartLink href={urlFactory.dashboard.inbox.index()} $transparent={transparent} css={styles}>
        {icon || <IconMail size='20px' />}
        {unreadMessages > 0 && <StyledNotificationBadge css={badgeStyles}>{unreadMessages}</StyledNotificationBadge>}
      </StyledSmartLink>
    </Box>
  )
}

InboxMenuItem.propTypes = {
  as: PropTypes.string,
  transparent: PropTypes.bool,
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  badgeStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  icon: PropTypes.node,
}

InboxMenuItem.defaultProps = {
  as: undefined,
  transparent: false,
  styles: undefined,
  badgeStyles: undefined,
  icon: null,
}
