import * as yup from 'yup'
import { eventSchema } from './event'
import { cast } from 'helpers/schema'

const wishlistSchema = yup.object({
  id: yup.number().required(),
  title: yup.string().required(),
  events: yup.array().of(eventSchema).default([]),
})

const wishlistsSchema = yup.array().of(wishlistSchema).default([])

function create(schema, data) {
  return cast(schema, data)
}

export const emptyWishlist = create(wishlistSchema)
export const emptyWishlists = create(wishlistsSchema)
