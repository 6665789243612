import styled from 'styled-components'
import { SmartLink } from 'components/shared/SmartLink'

export const SmartFooterLink = styled(SmartLink)`
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
  }
`
