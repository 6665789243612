import React from 'react'
import PropTypes from 'helpers/proptypes'
import Link from 'next/link'
import { useTrackingQueryStrings } from 'hooks/tracking'

/**
 * Use a Next.js Router Link component when the URL is relative, on the same hostname or not a string
 * Use a traditional anchor tag otherwise (full url on a different hostname, server side etc...)
 */
export const SmartLink = React.forwardRef(
  ({ prefetch, href: hrefWithNoCampaignParameters, children, locale, ...props }, ref) => {
    const keepTrackingQueryStrings = useTrackingQueryStrings()
    const href = keepTrackingQueryStrings(hrefWithNoCampaignParameters)
    // Early return for Link objects or functions
    if (typeof href === 'undefined' || href === null) return <a {...props}>{children}</a>
    if (typeof href !== 'string')
      return (
        <Link prefetch={prefetch} href={href} passHref locale={locale}>
          <a ref={ref} {...props}>
            {children}
          </a>
        </Link>
      )
    // Early return for anchors because Link can't handle them
    if (href.startsWith('#'))
      return (
        <a href={href} {...props}>
          <>{children}</>
        </a>
      )
    const isClient = typeof window !== 'undefined'
    const isSameHost = isClient && new URL(href, window.location).hostname === window.location?.hostname
    return isSameHost ? (
      <Link prefetch={prefetch} href={href} passHref locale={locale}>
        <a ref={ref} {...props}>
          {children}
        </a>
      </Link>
    ) : (
      <a href={href} {...props}>
        {children}
      </a>
    )
  },
)
SmartLink.displayName = 'SmartLink'
SmartLink.propTypes = {
  children: PropTypes.node,
  // React Router accepts strings, objects and functions.
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
  prefetch: PropTypes.bool,
  locale: PropTypes.bool,
}
SmartLink.defaultProps = {
  children: undefined,
  prefetch: false,
  locale: undefined,
}
