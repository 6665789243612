import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const organizationSchema = yup.object({
  id: yup.number().optional(),
  name: yup.string().required(),
  type: yup.string().nullable().default(null),
  online_payment: yup.boolean(),
  display_price: yup.boolean(),
  logo_url: yup.string().nullable().default(null),
})

export function create(data) {
  return cast(organizationSchema, data)
}

export const emptyOrganization = create()
