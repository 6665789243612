import React, { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { useLocale } from 'hooks/locales/useLocale'
import { useReferralWalletQuery } from 'hooks/queries/referralWallet'
import { getIsCurrentUserLoggedIn, getCurrentUser } from 'redux/selectors'
import { Text } from '@vizeat/components/es6/components/Text'
import { numbers } from '@vizeat/helpers'
import { urlFactory } from 'helpers/url'

export function useDashboardLinks({ hideAllNavItems, hideResumeApplication }) {
  const { t } = useTranslation()
  const currentUser = useSelector(getCurrentUser)
  const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)
  const { data: referralWallet, isLoading } = useReferralWalletQuery(currentUser.id)
  const { locale } = useLocale()
  const shouldShowResumeApplication = !hideResumeApplication && !currentUser.isHost && !!currentUser.host_application_id

  const referralText = useMemo(() => {
    if (isLoading || referralWallet || !referralWallet || referralWallet?.amount <= 0)
      return t('Header::Refer a friend')

    return (
      <>
        {t('Header::Refer a friend')}
        <Text as='span' color='primary' size='s' ml='4px'>
          {t('Header::<credit>(Credit : {{ credit }})</credit>', {
            credit: numbers.formatIntegerPrice(referralWallet.amount, locale, referralWallet.currency),
          })}
        </Text>
      </>
    )
  }, [isLoading, locale, referralWallet, t])

  if (!isCurrentUserLoggedIn || hideAllNavItems) return []

  const dashboardLinks = [
    shouldShowResumeApplication && {
      href: urlFactory.dashboard.hostApplication.resume(),
      text: t('Header::Resume application'),
    },
    currentUser.isHost && {
      href: urlFactory.dashboard.planning.index(),
      text: t('Header::Planning'),
    },
    { href: urlFactory.dashboard.inbox.index(), text: t('Header::Inbox') },
    currentUser.isHost && {
      href: urlFactory.dashboard.events.index(),
      text: t('Header::My Meals'),
    },
    currentUser.isHost && { href: urlFactory.dashboard.widget(), text: t('Header::Booking Widget') },
    currentUser.isHost && { href: urlFactory.dashboard.paymentHistory(), text: t('Header::Payment History') },
    { href: urlFactory.dashboard.users.profile(), text: t('Header::Edit my profile') },
    { href: urlFactory.dashboard.bookings.index(), text: t("Header::I'm guest") },
    { href: urlFactory.dashboard.wishlists(), text: t('Header::Wishlist::My favorite events') },
    { href: urlFactory.dashboard.users.payoutPreferences(), text: t('Header::My Account') },
    {
      href: urlFactory.dashboard.users.referral(),
      text: referralText,
    },
  ]

  return dashboardLinks.filter(Boolean)
}
