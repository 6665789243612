import { screenApi } from 'apis'

export async function getWishlists() {
  const { data } = await screenApi.get(`/wishlists`)
  return data.wishlists
}

export async function getWishlist(id) {
  const { data } = await screenApi.get(`/wishlists/${id}`)
  return data.wishlist
}

export async function createWishlist(payload) {
  const { data } = await screenApi.post(`/wishlists`, payload)
  return data.wishlist
}

export async function updateWishlist({ wishlistId, ...payload }) {
  const { data } = await screenApi.put(`/wishlists/${wishlistId}`, payload)
  return data.wishlist
}
