import { toCamelCase } from './string'
import { getLocalityAndCountry } from './places'
import moment from 'moment'
import { serializeQueryKey } from './query'

export const DEFAULT_SEATS = 2
const MULTI_FILTERS = ['mealtypes', 'languages', 'tags', 'foods', 'diets', 'amenities']
const NUMBER_FILTERS = ['price_min', 'price_max']
const BOOLEAN_FILTERS = ['allow_private_bookings', 'allow_public_bookings', 'online', 'instant_booking']

export const FILTER_QUERY_KEYS = [...MULTI_FILTERS, ...NUMBER_FILTERS, ...BOOLEAN_FILTERS]

export const SEARCH_RESULT_SIZE = 24
export const SEARCH_RESULT_MAP_SIZE = 250

export function hasFiltersSelected(query) {
  return Object.entries(query).some(([filter]) => FILTER_QUERY_KEYS.includes(filter))
}

function parseFilterParam(key, value) {
  let transformedValue = value

  if (MULTI_FILTERS.includes(key) && typeof value === 'string') {
    transformedValue = [value]
  }

  if (NUMBER_FILTERS.includes(key)) {
    transformedValue = Number(value)
  }

  if (BOOLEAN_FILTERS.includes(key)) {
    transformedValue = value === 'true'
  }

  return [toCamelCase(key), transformedValue]
}

export function getSearchFiltersFromRouterQuery(query, name) {
  if (name) {
    const [, value] = parseFilterParam(name, query[name])
    return value
  }

  const filters = Object.entries(query)
    .filter(([key]) => FILTER_QUERY_KEYS.includes(key))
    .map(([key, value]) => parseFilterParam(key, value))

  return Object.fromEntries(filters)
}

export function getSearchParamsFromRouterQuery(
  { datefrom, dateto, nbguests, q, pid, nelat, nelng, swlat, swlng, view, sort_by: sortBy, playlist, ...query },
  { includeFilters = true } = {},
) {
  const defaultDateRange = getDefaultSearchDateRange()
  const start = datefrom || defaultDateRange.start
  const end = datefrom && !dateto ? datefrom : dateto || defaultDateRange.end

  const payloadEntries = Object.entries({
    q,
    pid,
    sortBy,
    start,
    end,
    playlist: playlist ? Number(playlist) : undefined,
    nelat: nelat ? Number(nelat) : undefined,
    nelng: nelng ? Number(nelng) : undefined,
    swlat: swlat ? Number(swlat) : undefined,
    swlng: swlng ? Number(swlng) : undefined,
    seats: nbguests ? Number(nbguests) : DEFAULT_SEATS,
    size: view === viewConstants.VIEW_MAP ? SEARCH_RESULT_MAP_SIZE : SEARCH_RESULT_SIZE,
    ...(includeFilters && getSearchFiltersFromRouterQuery(query)),
  }).filter(([, value]) => typeof value !== 'undefined' && value !== '')

  return Object.fromEntries(payloadEntries)
}

export const viewConstants = {
  VIEW_MAP: 'map',
  VIEW_LIST: 'list',
}

export function buildSearchQueryKey(payload, currency) {
  return serializeQueryKey('search', payload, currency)
}

export function getSearchUrl({ place, ...query } = {}) {
  const qs = new URLSearchParams({
    q: getLocalityAndCountry({ place }),
    ...query,
  }).toString()

  return `/search?${qs}`
}

export function isMapSearch(query) {
  return ['nelat', 'nelng', 'swlat', 'swlng'].every((filter) => !!query[filter])
}

export function getDefaultSearchDateRange() {
  const today = moment.utc()
  return {
    start: today.format('YYYY-MM-DD'),
    end: today.clone().add(1, 'month').format('YYYY-MM-DD'),
  }
}
