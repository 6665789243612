import React from 'react'
import PropTypes from 'helpers/proptypes'
import { IconBell } from '@vizeat/components/es6/assets/icons'
import { ToggleWrapper, StyledNotificationBadge } from './styled'

export function Toggle({ unread, onClick, icon, styles, badgeStyles }) {
  return (
    <ToggleWrapper css={styles} onClick={onClick}>
      {icon || <IconBell size='20px' />}
      {unread > 0 && (
        <StyledNotificationBadge
          floating
          css={`
            ${badgeStyles}
            top: -14px;
            right: -2px;
          `}
        >
          {unread}
        </StyledNotificationBadge>
      )}
    </ToggleWrapper>
  )
}

Toggle.propTypes = {
  unread: PropTypes.number.isRequired,
  styles: PropTypes.string,
  badgeStyles: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
}

Toggle.defaultProps = {
  styles: undefined,
  badgeStyles: undefined,
  onClick: undefined,
  icon: null,
}
