import { useQuery } from '@tanstack/react-query'
import { getReferralWallet } from 'api/referralWallet'
import { STALE_TIME } from 'helpers/query'
import { emptyReferralWallet } from 'schemas/referralWallet'
import { screenApi } from 'apis'

export function useReferralWalletQuery(userId, reactQueryOptions) {
  return useQuery({
    staleTime: STALE_TIME,
    placeholderData: emptyReferralWallet,
    queryKey: ['referralWallet', 'userId', userId],
    queryFn: () => getReferralWallet(),
    enabled: !!userId && !!screenApi.getHeader('authorization'),
    ...reactQueryOptions,
  })
}
