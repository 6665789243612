import { useQuery } from '@tanstack/react-query'
import { fetchSearchAutoComplete } from 'api/search'
import { STALE_TIME } from 'helpers/query'
import { useMemo } from 'react'

function toSearchResult({ type, ...item }) {
  const result = {
    eventType: undefined,
    place: undefined,
    title: item.title,
    id: item.id,
    type,
  }

  if (type === 'places') {
    return {
      ...result,
      place: item.title,
    }
  }

  if (type === 'users') {
    return {
      ...result,
      place: item.subtitle,
    }
  }

  if (type === 'events') {
    return {
      ...result,
      place: item.subtitle,
      eventType: item.localized_event_type,
    }
  }

  return result
}

const initialData = [
  {
    type: 'places',
    items: [],
  },
  {
    type: 'users',
    items: [],
  },
  {
    type: 'events',
    items: [],
  },
]

const emptyAutocomplete = {
  places: [],
  users: [],
  events: [],
  allResults: [],
}

export function useSearchAutocompleteQuery({
  q = '',
  shouldSearchEvents = true,
  shouldSearchPlaces = true,
  shouldSearchUsers = true,
} = {}) {
  const categories = []
  if (shouldSearchEvents) categories.push('events')
  if (shouldSearchPlaces) categories.push('places')
  if (shouldSearchUsers) categories.push('users')
  const shouldIncludeCategories = categories.length > 0 && categories.length < 3

  const searchAutocompleteResult = useQuery({
    placeholderData: initialData,
    keepPreviousData: true,
    enabled: q.trim().length > 0,
    staleTime: STALE_TIME,
    queryKey: ['search', 'autocomplete', q, categories],
    queryFn: () => {
      return fetchSearchAutoComplete({
        q,
        ...(shouldIncludeCategories && { categories }),
      })
    },
    select: (data) => {
      let globalIndex = 0
      return data.reduce((acc, item) => {
        const items = item.items.map((i) => ({
          item: toSearchResult(i),
          globalIndex: globalIndex++,
        }))

        return {
          ...acc,
          [item.type]: items,
          allResults: acc.allResults.concat(items),
        }
      }, emptyAutocomplete)
    },
  })

  return useMemo(
    () => ({
      ...searchAutocompleteResult,
      autocompleteData: searchAutocompleteResult.data || emptyAutocomplete,
    }),
    [searchAutocompleteResult],
  )
}
